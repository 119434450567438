import { ThemeOptions } from "@mui/material";

export const light: ThemeOptions = {
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Scrollbar styles based on theme mode
        body: {
          scrollbarColor: "#b1b1b1 #ffffff",
          "&::-webkit-scrollbar": {
            backgroundColor: "#ffffff",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            minHeight: 24,
            border: `2px solid "#ffffff"`,
          },
          "&::-webkit-scrollbar-thumb:focus": { backgroundColor: "#444" },
          "&::-webkit-scrollbar-thumb:active": { backgroundColor: "#444" },
          "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#444" },
          "&::-webkit-scrollbar-corner": { backgroundColor: "#ffffff" },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    neutralbackground: {
      main: "#1e1e1e",
      contrastText: "#fff",
    },
    primary: {
      main: "#0288d1",
      selected: "rgba(2, 136, 209, 0.16)",
    },
    secondary: {
      main: "#444444",
    },
    background: {
      area: "rgba(0, 0, 0, 0.02)",
      label: "rgba(0, 0, 0, 0.05)",
    },

  },
  typography: {
    fontSize: 12,
    fontFamily: "Inter, sans-serif",
  },
};

export const dark: ThemeOptions = {
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Scrollbar styles based on theme mode
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar": { backgroundColor: "#2b2b2b" },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "2px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus": { backgroundColor: "#ffffff" },
          "&::-webkit-scrollbar-thumb:active": { backgroundColor: "#ffffff" },
          "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#ffffff" },
          "&::-webkit-scrollbar-corner": { backgroundColor: "#2b2b2b" },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    neutralbackground: {
      main: "#1e1e1e",
      contrastText: "#fff",
    },
    primary: {
      main: "#0288d1",
      selected: "rgba(2, 136, 209, 0.16)",
    },
    secondary: {
      main: "#444444",
    },
    background: {
      area: "rgba(255, 255, 255, 0.02)",
      label: "rgba(255, 255, 255, 0.05)",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: "Inter, sans-serif",
  },
};
