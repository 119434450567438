import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ImportData } from '../utils/import-data-context';
import { Box,Typography } from '@mui/material';
import { useState } from 'react';
import { defaultStepImporterConfigurations, importStepFileFromBuffer, isStepFile, StepImporterConfigurations } from "../components/toolbar/ModelFileImporter";
import FTextField from '../components/FTextField';
import FButton from '../components/FButton';

type StepFileDialogProps = { importData: ImportData, onLoadMessage: (message: string) => void; onClose: () => void };

export default function StepFileDialog({ importData, onLoadMessage, onClose }: StepFileDialogProps) {

    const [tessellationAngle, setTessellationAngle] = useState<number>(defaultStepImporterConfigurations.tessellationAngle);
    const [tessellationLineDeflection, setTessellationLineDeflection] = useState<number>(defaultStepImporterConfigurations.tessellationLineDeflection);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const importStepFile = () => {
        const { buffer, filename } = importData;
        console.log(`Importing step file: ${filename} size=${buffer.byteLength}`);
        const configurations: StepImporterConfigurations = {
            tessellationAngle: tessellationAngle,
            tessellationLineDeflection: tessellationLineDeflection
        }
        importStepFileFromBuffer(buffer, filename, configurations);
        onLoadMessage("Importing STEP file...");
        onClose();
    };

    const validateInputs = () => {
        const errors: string[] = [];
        if (tessellationAngle < 0.1 || tessellationAngle > 1.0) {
            errors.push("Angular deflection must be between 0.1 and 1.0 radians.");
        }
        if (tessellationLineDeflection < 0.01 || tessellationLineDeflection > 0.1) {
            errors.push("Line deflection must be between 0.01 and 0.1.");
        }
        setErrorMessages(errors);
        return errors.length === 0;
    };

    const handleSubmit = () => {
        if (validateInputs()) {
            importStepFile();
        }
    };
    return (
        <Dialog open={!!importData && isStepFile(importData.filename)} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                    Import {importData?.filename}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h6" gutterBottom paddingBottom='1em'>
                    Tessellation
                </Typography>
                <Box paddingLeft='1em'>
                    <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={2}>
                        <span>Angular deflection in radians (0.1 - 1.0)</span>
                        <FTextField
                            type="number"
                            inputProps={{ step: 0.05, min: 0.1, max: 1.0 }}
                            value={tessellationAngle}
                            onChange={(e) => setTessellationAngle(parseFloat(e.target.value))}
                            error={tessellationAngle < 0.1 || tessellationAngle > 1.0}
                            helperText={
                                tessellationAngle < 0.1 || tessellationAngle > 1.0
                                    ? 'Value must be between 0.1 and 1.0 radians.'
                                    : ''
                            }
                        />
                    </Box>
                    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                        <span>Line deflection (0.01 - 0.1)</span>
                        <FTextField
                            type="number"
                            inputProps={{ step: 0.01, min: 0.01, max: 0.1 }}
                            value={tessellationLineDeflection}
                            onChange={(e) => setTessellationLineDeflection(parseFloat(e.target.value))}
                            error={tessellationLineDeflection < 0.01 || tessellationLineDeflection > 0.1}
                            helperText={
                                tessellationLineDeflection < 0.01 || tessellationLineDeflection > 0.1
                                    ? 'Value must be between 0.01 and 0.1.'
                                    : ''
                            }
                        />
                    </Box>
                </Box>
                {errorMessages.length > 0 && (
                    <Box mt={2} color="error.main">
                        {errorMessages.map((error, index) => (
                            <Typography key={index} color="error">
                                {error}
                            </Typography>
                        ))}
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <FButton onClick={onClose} color="secondary">
                    Cancel
                </FButton>
                <FButton onClick={handleSubmit} color="primary" variant="contained">
                    Import
                </FButton>
            </DialogActions>
        </Dialog >

    )
}