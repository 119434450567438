import React, { useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Menu, MenuItem, Box, IconButton, Tooltip } from '@mui/material';
import { useMaterials } from '../MaterialsContext';
import { useDragItemContext } from '../DragItemContext';
import { useLibraryUpdateContext } from '../LibraryReloadContext'; 
import SaveToLibraryIcon from '@mui/icons-material/SaveOutlined';
import MergeIcon from '@mui/icons-material/MergeTypeOutlined';
import AddIcon from '@mui/icons-material/AddOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// Define Material interface
interface Material {
  id: number;
  name: string;
  type: string;
  baseColor: { x: number; y: number; z: number };
}

interface MaterialListProps {
  search: string;  // New prop to filter material names
}

const MaterialList = ({ search }: MaterialListProps) => {
  const { materials, selectedMaterialIds, setSelectedMaterialIds } = useMaterials();
  const { setDraggedItem } = useDragItemContext();
  const { reloadLibrary } = useLibraryUpdateContext();

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    materialId: number | null;
  } | null>(null);

  // Handle right-click (context menu)
  const handleRightClick = (event: React.MouseEvent, materialId: number) => {
    // If the item is not already selected, add it to the selection
    setSelectedMaterialIds((prevSelected: number[]) => {
      if (!prevSelected.includes(materialId)) {
        return [...prevSelected, materialId];
      }
      return prevSelected;
    });

    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      materialId: materialId,
    });
  };

  // Utility function to convert a base64 string to a Blob
  function base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(',')[1]); // Remove the data URL prefix
    const byteArrays = [];

    for (let i = 0; i < byteString.length; i += 512) {
      const slice = byteString.slice(i, i + 512);
      const byteNumbers = new Array(slice.length);
      for (let j = 0; j < slice.length; j++) {
        byteNumbers[j] = slice.charCodeAt(j);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  }

  const handleMerge = async () => {
    const lastMatId = selectedMaterialIds[0];
    const lastMaterial = globalThis.lys.getMaterialById(lastMatId);

    const allNodes: number[] = globalThis.lys.getAllNodeIds();
    allNodes.forEach((nodeId) => {
      const node = globalThis.lys.getNodeById(nodeId);
      if (node) {
        const mat = node.getMaterial();
        if (mat && selectedMaterialIds.includes(mat.getId())) {
          globalThis.lys.setMaterial(nodeId, lastMatId, true);
        }
      }
    });

    selectedMaterialIds.forEach((matId) => {
      if (matId != lastMatId) globalThis.lys.deleteMaterialById(matId, true);
    });
  };

  const handleDelete = async () => {
    selectedMaterialIds.forEach((matId) => {
      globalThis.lys.deleteMaterialById(matId, true);
    });
  };


  const canSaveMaterialToLibrary = (): boolean => {
    if (!selectedMaterialIds || selectedMaterialIds.length === 0) return false; 

    const mat = globalThis.lys.getMaterialById(selectedMaterialIds[0]);
    return mat?.getName() && mat.getName().trim().length > 0;

    return false;
  }

  const handleAddMaterial = () => {
    globalThis.lys.createMaterialGenId("New Material", "Lambertian");
  };

  const handleSaveMaterialToLibrary = () => {
    if (!selectedMaterialIds || selectedMaterialIds.length === 0) return false; 

      const mat = globalThis.lys.getMaterialById(selectedMaterialIds[0]);

      if (!mat) {
        console.warn("Material " + selectedMaterialIds[0] + " not found during save to library");
        return;
      }

      const form = new FormData();
      const materialBlob = new Blob([mat.toJSONString()], { type: 'application/json' });
      form.set("material", materialBlob);

      const image = globalThis.lys.renderThumbnail(selectedMaterialIds[0]);

      // wait 3 seconds (for the render to render thumbnail then call do remaning code
      setTimeout(() => {

        const base64string = globalThis.lys.getThumbnail();
        const thumbnailBlob = base64ToBlob(base64string, 'image/png');
        form.set("thumbnail", thumbnailBlob); // Use the thumbnail from getThumbnail()

        fetch("/api/materials/", {
          method: "POST",
          body: form,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to save material');
            }
            return response.text();
          })
          .then((result) => {
            console.log('Material saved:', result);
            reloadLibrary(); // Trigger a reload of the library
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }, 3000);
  };

  // Handle material selection updates
  const handleNodesSelected = (event: React.MouseEvent, selectedIds: string[]) => {
    const selectedIdsAsNumbers = selectedIds.map(Number);  // Convert string IDs to numbers
    setSelectedMaterialIds(selectedIdsAsNumbers);
  };

  // Helper function to convert the baseColor (x, y, z) into an RGB string
  const colorToRGB = (color: { x: number; y: number; z: number }): string => {
    const r = Math.round(color.x * 255);
    const g = Math.round(color.y * 255);
    const b = Math.round(color.z * 255);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const handleDragStart = (material, e) => {
    setDraggedItem({ materialId: material.id }, "materialList");
  
  };
  // Filter materials based on the search prop
  const filteredMaterials = materials.filter((material: Material) =>
    material.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxSizing: 'border-box' }}
    // onDragOver={handleDragOver} // todo: allow drag of materials
    // onDrop={handleDropToRoot}
    >
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <SimpleTreeView
          multiSelect
          selectedItems={selectedMaterialIds.map(String)}  // Convert number IDs to strings for selection
          onSelectedItemsChange={handleNodesSelected}  // Update selection when items are selected
        >
          {filteredMaterials.map((material: Material) => (
            <TreeItem

              draggable={true}

              onDragStart={(e) => handleDragStart(material, e)}  // Set dragged file on drag start

              key={material.id}
              itemId={material.id.toString()}

              // Place the Box in the endIcon slot
              slots={{
                endIcon: () => (
                  <Box
                    component="span"
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      backgroundColor: colorToRGB(material.baseColor),
                      border: '1px solid grey', // Add border for the outline
                    }}
                  />
                ),
              }}

              label={
                <div
                  onContextMenu={(event) => handleRightClick(event, material.id)}
                  style={{
                    padding: '4px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* Material Name - Flex Grow to take up space */}
                  <span style={{ flexGrow: 1 }}>
                    {material.name}
                  </span>

                  {/* Material Type - Aligned to Far Right */}
                  <span>
                    {material.type}
                  </span>
                </div>
              }
            />
          ))}
        </SimpleTreeView>
      </Box>

      {/* Fixed Row of Buttons with Tooltips */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1em' }}>
        <Tooltip title="Add Material" arrow>
          <span>
            <IconButton
              aria-label="Add Material"
              onClick={handleAddMaterial}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Create material variant set" arrow>
          <span>
            <IconButton
              aria-label="Create material variant set"
              onClick={() => { }}
            >
              <CreateNewFolderIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip> 
        <Tooltip title="save to Library" arrow>
          <span>
            <IconButton
              aria-label="save to Library"
              onClick={handleSaveMaterialToLibrary}
              disabled={!canSaveMaterialToLibrary() || selectedMaterialIds.length != 1}
            >
              <SaveToLibraryIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Merge materials" arrow>
          <span>
            <IconButton
              aria-label="Merge materials"
              onClick={handleMerge}
              disabled={selectedMaterialIds.length <= 1}>
              <MergeIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <span>
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              disabled={selectedMaterialIds.length == 0}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>

      </Box>

      {/* Context Menu for Right Click */}
      <Menu
        open={contextMenu !== null}
        onClose={(e) => { setContextMenu(null); }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => { handleSaveMaterialToLibrary(); setContextMenu(null); }} disabled={!canSaveMaterialToLibrary() || selectedMaterialIds.length != 1}>Save to Library</MenuItem>
        <MenuItem
          onClick={() => { handleMerge(); setContextMenu(null); }}
          disabled={selectedMaterialIds.length <= 1}
        >
          Merge with {selectedMaterialIds.length > 0 ? materials.find(material => material.id === selectedMaterialIds[0])?.name || "self" : "self"}
        </MenuItem>

      </Menu>
    </Box>
  );
};

export default MaterialList;
