import React from 'react';
import Button from '@mui/material/Button';

const FButton = ({ children, sx = {}, ...props }) => {
    return (
        <Button
            variant="contained"            
            size="small"
            sx={{
                borderRadius: '5px',
                height: 32,
                textTransform: 'none',
                // Merge custom sx with provided sx prop
                ...sx, 
            }}
            {...props} // Spread the rest of the props to retain original functionality
        >
            {children}
        </Button>
    );
};

export default FButton;