// actions.tsx

import React from 'react';
import AddIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveToLibraryIcon from '@mui/icons-material/SaveOutlined';
import MergeIcon from '@mui/icons-material/MergeTypeOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CenterFocusIcon from '@mui/icons-material/CenterFocusStrong';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import type { SvgIconComponent } from '@mui/icons-material';

export type ActionKey =
    | 'clone'
    | 'delete'
    | 'groupParts'
    | 'createGroup'
    | 'unlinkMaterial'
    | 'linkMaterial'
    | 'saveMaterialToLibrary'
    | 'lockNode'
    | 'unlockNode'
    | 'hideNode'
    | 'showHiddenNode'
    | 'centerAndFit'
    | 'showAllParts'
    | 'addCamera';

export interface ActionContext {
    selectedNodeIds: number[];
    setSelectedNodeIds: React.Dispatch<React.SetStateAction<number[]>>
    rootNodeId?: number;
    reloadLibrary?: () => void;
}

interface Action {
    label: string | ((context: ActionContext) => string);
    icon?: SvgIconComponent;
    handler: (context: ActionContext) => void;
    disabled?: (context: ActionContext) => boolean;
    keyboardShortcut?: string;
}

const disableLeafNodeIf = (disableActionFunction: (node) => boolean): (ActionContext) => boolean => {
    const disableFunction = (context: ActionContext) => {
        let disable = false;
        const ids = context?.selectedNodeIds || [];
        disable = ids.every((id) => {
            const node = globalThis.lys.getNodeById(id);
            return node && node.getChildren().size() == 0 && disableActionFunction(node)
        });
        return disable;
    };
    return disableFunction;
}


const actions: Record<ActionKey, Action> = {
    addCamera: {
        label: 'Add Camera',
        icon: AddIcon,
        handler: (context: ActionContext) => {
            const newNodeId = globalThis.lys.createCameraGenId('New Camera');
            globalThis.lys.setActiveCamera(newNodeId, true);
            globalThis.lys.reparentNode(context.rootNodeId, newNodeId, true);
            context.setSelectedNodeIds([newNodeId]);
        },
    },
    lockNode: {
        label: 'Lock Part',
        icon: LockIcon,
        handler: (context: ActionContext) => {
            const nodeIds: number[] = context.selectedNodeIds || [];
            nodeIds.forEach((id) => {
                globalThis.lys.setLocked(id, true, true);
            });
        },
        disabled: disableLeafNodeIf((node) => node.isLocked()),
    },
    unlockNode: {
        label: 'Unlock Part',
        icon: LockOpenIcon,
        handler: (context: ActionContext) => {
            const nodeIds: number[] = context.selectedNodeIds || [];
            nodeIds.forEach((id) => {
                globalThis.lys.setLocked(id, false, true);
            });
        },
        disabled: disableLeafNodeIf((node) => !node.isLocked()),
    },
    hideNode: {
        label: 'Hide Part',
        icon: VisibilityOffIcon,
        handler: (context: ActionContext) => {
            const nodeIds: number[] = context.selectedNodeIds || [];
            nodeIds.forEach((id) => {
                globalThis.lys.setHidden(id, true, true);
            });
        },
        disabled: disableLeafNodeIf((node) => node.isHidden()),
    },
    showHiddenNode: {
        label: 'Show Hidden Part',
        icon: VisibilityIcon,
        handler: (context: ActionContext) => {
            const nodeIds: number[] = context.selectedNodeIds || [];
            nodeIds.forEach((id) => {
                globalThis.lys.setHidden(id, false, true);
            });
        },
        disabled: disableLeafNodeIf((node) => !node.isHidden()),
    },
    saveMaterialToLibrary: {
        label: 'Save Material to Library',
        icon: SaveToLibraryIcon,
        handler: (context: ActionContext) => {

            const selectedMaterialIds = context.selectedNodeIds
                .map((id) => {
                    const node = globalThis.lys.getNodeById(id);
                    return node ? node.getMaterial()?.getId() : undefined;
                })
                .filter((id) => id !== undefined);

            //make selectedMaterialIds unique
            const uniqueMatIds = [...new Set(selectedMaterialIds)] as number[];

            if (!selectedMaterialIds || selectedMaterialIds.length === 0) return false;

            async function processMaterials(ids) {
                for (let id of ids) {
                    await processMaterial(id);
                }
            }

            async function processMaterial(id) {
                const mat = globalThis.lys.getMaterialById(id);

                if (!mat) {
                    console.warn("Material " + id + " not found during save to library");
                    return;
                }

                const form = new FormData();
                const materialBlob = new Blob([mat.toJSONString()], { type: 'application/json' });
                form.set("material", materialBlob);

                // Call renderThumbnail for the current material ID
                globalThis.lys.renderThumbnail(id);

                // Wait 3 seconds for the thumbnail to render
                await new Promise((resolve) => setTimeout(resolve, 3000));

                // Get the rendered thumbnail
                const base64string = globalThis.lys.getThumbnail();
                const thumbnailBlob = base64ToBlob(base64string, 'image/png');
                form.set("thumbnail", thumbnailBlob);

                try {
                    const response = await fetch("/api/materials/", {
                        method: "POST",
                        body: form,
                    });
                    if (!response.ok) {
                        throw new Error('Failed to save material');
                    }
                    const result = await response.text();
                    console.log('Material saved:', result);
                    context.reloadLibrary(); // Trigger a reload of the library
                } catch (error) {
                    console.error('Error:', error);
                }
            }

            processMaterials(uniqueMatIds);

        },
        disabled: (context: ActionContext) => { return false }
    },
    clone: {
        label: 'Clone Part',
        icon: FileCopyIcon,
        handler: (context: ActionContext) => {
            const nodeIds: number[] = context.selectedNodeIds || [];
            const newIds: number[] = [];
            nodeIds.forEach((id) => {
                const newId: number = globalThis.lys.cloneSubtree(id, true);
                newIds.push(newId);
            });
            context.setSelectedNodeIds(newIds);
        },
        disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
    },
    delete: {
        label: 'Delete Part',
        icon: DeleteIcon,
        handler: (context: ActionContext) => {
            const nodeIds: number[] = context.selectedNodeIds || [];
            nodeIds.forEach((id) => {

                globalThis.lys.deleteSubtree(id, true);
            });
            context.setSelectedNodeIds([]);
        },
        disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
    },
    groupParts: {
        label: 'Group Parts',
        icon: CreateNewFolderIcon,
        handler: (context: ActionContext) => {
            const selectedNodeIds: number[] = context.selectedNodeIds || [];
            if (selectedNodeIds.length === 0) {
                return;
            }
            const newId: number = globalThis.lys.createNodeGenId('Group');
            const parentId: number = globalThis.lys.getNodeById(selectedNodeIds[0]).getParent().getId();
            globalThis.lys.reparentNode(parentId, newId, true);
            selectedNodeIds.forEach((id) => {
                globalThis.lys.reparentNode(newId, id, true);
            });
            const newSelectedNodeIds = [...new Set([...selectedNodeIds, newId])];
            context.setSelectedNodeIds(newSelectedNodeIds);

        },
        disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 0),
    },
    createGroup: {
        label: 'Create Group',
        icon: CreateNewFolderIcon,
        handler: (context: ActionContext) => {
            const newId: number = globalThis.lys.createNodeGenId('Group');
            globalThis.lys.reparentNode(context.rootNodeId, newId, true);
            globalThis.lys.setSelectedNodeIds([newId]);

            //context.setSelectedNodeIds([newId]);
        },
        disabled: (context: ActionContext) => false,
    },
    unlinkMaterial: {
        label: 'Separate Materials',
        icon: LinkOffIcon,
        handler: (context: ActionContext) => {
            const selectedNodeIds: number[] = context.selectedNodeIds || [];
            let materialsToDelete: number[] = [];
            selectedNodeIds.forEach((id) => {
                const material = globalThis.lys.getNodeById(id).getMaterial();
                if (material) {
                    materialsToDelete.push(material.getId());
                }
            });
            // make unique
            materialsToDelete = [...new Set(materialsToDelete)];

            selectedNodeIds.forEach((id) => {
                const material = globalThis.lys.getNodeById(id).getMaterial();
                if (material) {

                    const jsonMat: string = material.toJSONString();
                    const newMatId = globalThis.lys.createMaterialFromJson(jsonMat);
                    globalThis.lys.setMaterial(id, newMatId, true);
                }
            });

            //delete old materials
            materialsToDelete.forEach((matId) => {
                globalThis.lys.deleteMaterialById(matId, true);
            });
        },
        disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 1),
    },
    linkMaterial: {
        label: 'Join Materials',
        icon: LinkIcon,
        handler: (context: ActionContext) => {
            const selectedNodeIds: number[] = context.selectedNodeIds || [];

            const selectedMaterialIds = selectedNodeIds.map((id) => {
                const node = globalThis.lys.getNodeById(id);
                return node ? node.getMaterial()?.getId() : undefined;
            });
            // make unique and remove undefined from selectedMaterialsId

            const lastMatId = selectedMaterialIds[selectedMaterialIds.length - 1];

            const uniqueMatIds = [...new Set(selectedMaterialIds.filter((x) => x !== undefined))] as number[];

            const allNodes: number[] = globalThis.lys.getAllNodeIds();
            allNodes.forEach((nodeId) => {
                const node = globalThis.lys.getNodeById(nodeId);
                if (node) {
                    const mat = node.getMaterial();
                    if (mat && uniqueMatIds.includes(mat.getId())) {
                        globalThis.lys.setMaterial(nodeId, lastMatId, true);
                    }
                }
            });

            uniqueMatIds.forEach((matId) => {
                if (matId != lastMatId) globalThis.lys.deleteMaterialById(matId, true);
            });
        },
        disabled: (context: ActionContext) => !(context.selectedNodeIds && context.selectedNodeIds.length > 1),
    },
    centerAndFit: {
        label: 'Center and Fit',
        icon: CenterFocusIcon,
        handler: (context: ActionContext) => {
            globalThis.lys._setCenterAndFit();
        },
    },
    showAllParts: {
        label: 'Show All Parts',
        icon: VisibilityIcon,
        handler: (context: ActionContext) => {
            globalThis.lys.showAllParts();
        },
    },
};

// Utility function to convert a base64 string to a Blob
function base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(',')[1]); // Remove the data URL prefix
    const byteArrays = [];

    for (let i = 0; i < byteString.length; i += 512) {
        const slice = byteString.slice(i, i + 512);
        const byteNumbers = new Array(slice.length);
        for (let j = 0; j < slice.length; j++) {
            byteNumbers[j] = slice.charCodeAt(j);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
}



export { actions };
