// Common MIME type mappings
const mimeToExtMap = new Map<string, string[]>([
  ["image/jpeg", ["jpg", "jpeg"]],
  ["image/png", ["png"]],
  ["image/gif", ["gif"]],
  ["image/hdr", ["hdr"]],
  ["image/vnd.adobe.photoshop", ["psd"]],
  ["image/ktx2", ["ktx2"]],
]);

// Create reverse mapping
const extToMimeMap = new Map<string, string>();
for (const [mimeType, extensions] of mimeToExtMap) {
  for (const ext of extensions) {
    extToMimeMap.set(ext.toLowerCase(), mimeType);
  }
}

/**
 * Convert MIME type to file extension
 * Returns the first/primary extension for the MIME type
 */
export function mimeTypeToExtension(mimeType: string): string | undefined {
  const extensions = mimeToExtMap.get(mimeType);
  return extensions?.[0];
}

/**
 * Convert file extension to MIME type
 */
export function extensionToMimeType(extension: string): string | undefined {
  // Remove leading dot if present and convert to lowercase
  const ext = extension.replace(/^\./, "").toLowerCase();
  return extToMimeMap.get(ext);
}

/**
 * Get all extensions for a MIME type
 */
export function getAllExtensionsForMimeType(mimeType: string): string[] {
  return mimeToExtMap.get(mimeType) ?? [];
}
