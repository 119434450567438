import LockClosedIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState, useRef } from "react";
import { useSelectedNodes } from '../SelectedNodesContext';
import NumberInput from '../NumberInput';
import FButton from '../FButton';
import { Box } from '@mui/system';

interface Props {
    nodeId: number | undefined;
}

export default function CameraProperties({nodeId} : Props) {
    const [translationX, setTranslationX] = useState<number | undefined>(undefined);
    const [translationY, setTranslationY] = useState<number | undefined>(undefined);
    const [translationZ, setTranslationZ] = useState<number | undefined>(undefined);

    const [rotationX, setRotationX] = useState<number | undefined>(undefined);
    const [rotationY, setRotationY] = useState<number | undefined>(undefined);
    const [rotationZ, setRotationZ] = useState<number | undefined>(undefined);

    const [targetX, setTargetX] = useState<number | undefined>(undefined);
    const [targetY, setTargetY] = useState<number | undefined>(undefined);
    const [targetZ, setTargetZ] = useState<number | undefined>(undefined);

    const [forceUpdate, setForceUpdate] = useState(0);


    const valuesChanged = (last: number[], ...values: number[]) => {
        if (last.length !== values.length) return true;
        for (let i = 0; i < last.length; i++) {
            if (last[i] !== values[i]) return true;
        }
        return false;
    }

    const getData = () => {
        if (!nodeId) return;

        const currentValues = globalThis.lys.getTransformRotationScale(nodeId, true);
        setTranslationX(currentValues[0]);
        setTranslationY(currentValues[1]);
        setTranslationZ(currentValues[2]);

        setRotationX(currentValues[3]);
        setRotationY(currentValues[4]);
        setRotationZ(currentValues[5]);

        
    }

    useEffect(() => {
       
    }, []);

    useEffect(() => {
        globalThis.updateActiveCamera = () => setForceUpdate((n) => n + 1);
        getData();

        return () => {
            delete globalThis.updateSelectedTransforms;
        };
    }, []);

    useEffect(() => {
        getData();
    }, [forceUpdate]);

    useEffect(() => {
        if (!nodeId) return;
        if (translationX == undefined || translationY == undefined || translationZ == undefined || 
            rotationX == undefined || rotationY == undefined || rotationZ == undefined ||
            targetX == undefined || targetY == undefined || targetZ == undefined) return;

        const currentValues = globalThis.lys.getTransformRotation(nodeId, true);

        if (valuesChanged(currentValues, translationX, translationY, translationZ, rotationX, rotationY, rotationZ, targetX, targetY, targetZ))
            globalThis.lys.setTranslateRotation(nodeId,
                translationX, translationY, translationZ,
                rotationX, rotationY, rotationZ,
                targetX, targetY, targetZ,
                true
            );
    }, [translationX, translationY, translationZ, rotationX, rotationY, rotationZ, targetX, targetY, targetZ]);

    return (
        <>
            <Grid container spacing={0} padding={1} columns={12} alignItems="center" justifyContent="center" rowSpacing={1} columnSpacing={1}>
                <Grid item xs={3}><Typography align="left">Translation:</Typography></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} value={translationX} setValue={setTranslationX} /></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} value={translationY} setValue={setTranslationY} /></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} value={translationZ} setValue={setTranslationZ} /></Grid>

                <Grid item xs={3}><Typography align="left">Rotation:</Typography></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} adornment="°" step={15} value={rotationX} setValue={setRotationX} /></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} adornment="°" step={15} value={rotationY} setValue={setRotationY} /></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} adornment="°" step={15} value={rotationZ} setValue={setRotationZ} /></Grid>

                <Grid item xs={3}><Typography align="left">Target:</Typography></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} value={targetX} setValue={setTargetX} /></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} value={targetY} setValue={setTargetY} /></Grid>
                <Grid item xs={3}><NumberInput disabled={!nodeId} value={targetZ} setValue={setTargetZ} /></Grid>
            </Grid>

        </>
    );
}
