import React, { ReactNode, createContext, useContext, useState, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { actions, ActionKey, ActionContext } from './actions';
import { useSelectedNodes } from './SelectedNodesContext'; // import useSelectedNodes

type ShortcutConfig = Record<ActionKey, string>;

/*
KeyShot shortcuts

Movetool
t,r,s
x,y,z

Select part and open move tool
Ctrl+d

Camera
Tumble: LMB Drag
Pan: MMB Drag
Cycle Camera: ↑+N
Dolly: Wheel
Focal Length: Alt+Wheel
Perspective: Alt+↑+RMB~
Twist: Alt+Ctrl+LMB
Select Target: Ctrl+LMB
Depth of Field: D
Center & Fit Models: Ctrl+Alt+↑+RMB
Reset Camera: Ctrl+R
Environment
Brightness (Large Change): Up/Down
Brightness (Small Change): Left/Right
Rotate: Ctrl+LMB Drag
Flatten Ground: G
Show:
Environment: E
Color: C
Backdrop: B
Open Environment: Ctrl+E
Open Backplate: Ctrl+B
File
New Project: Ctrl+N
Open Project: Ctrl+O
Save: Ctrl+S
Save As: Ctrl+Alt+S
Quit: Ctrl+Q
Import Model: Ctrl+I
General
Copy: Ctrl+C
Paste: Ctrl+V
Cut: Ctrl+X
Undo: Ctrl+Z
Delete: Del
Redo: Ctrl+Y
Screenshot: P
Render: Ctrl+P
Interface
Project: Space
Toolbar: T
Library: M
Ribbon: R
KeyShot XR: X
Heads-Up Display: H
Full Screen: F
Presentation Mode: ↑+F
Coordinate Legend: Z
Geometry View: O
Show Only: Alt+LMB
Hide Part: Alt+Ctrl+LMB
Cycle Model Sets: ↑+M
Light Sources: L
Marquee Selection: ↑+LMB Drag*
Hotkeys: K
*Right to left = Cross, Left to right = Window
Adjust Value Fields: CTRL+MMB Scroll (Shift for minor steps)
Materials
Edit Material: Dbl Click
Select Material: ↑+LMB
Apply Selected: 1+RMB
Apply Copy: Ctrl+↑+RMB
Real-time
Performance Mode: Alt+P
Selection Outlines: Alt+S
Pause: Ctrl+P
Global Illumination: I
Self Shadows: S
Render NURBS: N
Render Region: Ctrl+↑+R
Animation
Play/Pause: ↑+Space
Animation Timeline: A


*/

const defaultShortcuts: ShortcutConfig = {
  addCamera: 'ctrl+a',
  lockNode: 'ctrl+l',
  unlockNode: 'ctrl+u',
  hideNode: 'ctrl+h',
  showHiddenNode: 'ctrl+shift+h',
  saveMaterialToLibrary: 'ctrl+s',
  clone: 'ctrl+c',
  delete: 'ctrl+delete',
  groupParts: 'ctrl+g',
  createGroup: 'ctrl+shift+g',
  unlinkMaterial: 'ctrl+shift+u',
  linkMaterial: 'ctrl+shift+l',
  centerAndFit: 'Ctrl+Alt+shift+c',
  showAllParts: 'ctrl+shift+p',
};

const KeyboardShortcutsContext = createContext<{
  shortcuts: ShortcutConfig;
  setShortcut: (action: ActionKey, shortcut: string) => void;
}>(null!);

export const KeyboardShortcutsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [shortcuts, setShortcuts] = useState(defaultShortcuts);
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodes(); // Access selectedNodeIds from SelectedNodesContext

  const context: ActionContext = {
    selectedNodeIds,
    setSelectedNodeIds,
    // Other context properties can be defined here or be fetched as needed
  };

  const setShortcut = useCallback((action: ActionKey, shortcut: string) => {
    setShortcuts((prev) => ({ ...prev, [action]: shortcut }));
  }, []);

  // Register keyboard shortcuts for each action
  Object.entries(actions).forEach(([actionKey, action]) => {
    const shortcut = shortcuts[actionKey as ActionKey];
    useHotkeys(shortcut, () => action.handler(context), [context]);
  });

  return (
    <KeyboardShortcutsContext.Provider value={{ shortcuts, setShortcut }}>
      {children}
    </KeyboardShortcutsContext.Provider>
  );
};

export const useKeyboardShortcuts = () => useContext(KeyboardShortcutsContext);
