import { Button, IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    AnyParameter,
    TextureParameter,
    useMaterials,
} from "../MaterialsContext";
import { useState, useEffect, ChangeEvent, KeyboardEvent, useRef } from "react";
import { useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { FileImporter } from "../toolbar/FileImporter";
import { ThumbnailWithRetry } from "../asset-library/ThumbnailWithRetry";
import FileDropZone from "../FileDropZone";
import { useLoaderData } from "react-router-dom";
import { Access } from "../share/AccessMenu";
import { extensionToMimeType } from "../../utils/mime-types";
import StyledBox from "../StyledBox";



interface MaterialPropertiesProps {
    materialTypes: string[];
    sceneId: string;
}
export default function MaterialProperties({
    materialTypes,
    sceneId,
}: MaterialPropertiesProps) {
    const {
        selectedMaterialProperties,
        setMaterialType,
        setMaterialParameter,
    } = useMaterials();

    const fileImporter = useRef<typeof FileImporter>();
    const uploadingTextureParameter = useRef<TextureParameter>();
    const [name, setName] = useState(selectedMaterialProperties[0]?.name || "");
    const [overrideTextureThumbnail, setOverrideTextureThumbnail] = useState<
        string | null
    >(null);

    const { access } = useLoaderData() as { access: Access };

    const handleNameChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setName(e.target.value);
    };

    useEffect(() => {
        if (selectedMaterialProperties[0]) {
            setName(selectedMaterialProperties[0].name || "");
        }
    }, [selectedMaterialProperties]);

    const backgroundColor = (color: string) => {
        const s = { background: "#ff0000", border: "1px solid #C4C4C4" };
        s.background = color;
        return s;
    };

    function throttle(func: (...args: any) => void, limit: number) {
        let inThrottle: boolean;
        return function (...args: any) {
            if (!inThrottle) {
                func.apply(this, args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    }

    // Inside your component
    const throttledSetMaterialParameter = useCallback(
        throttle((name, value) => {
            setMaterialParameter(name, value);
        }, 100), // 200ms delay
        [],
    );

    function handleClickTexture(entry: TextureParameter): void {
        if (!!fileImporter.current) {
            uploadingTextureParameter.current = entry;
            (fileImporter.current as unknown as FileImporter).openDialog();
        }
    }

    function handleUnselectTexture(entry: TextureParameter): void {
        setMaterialParameter(entry.name, -1);
    }

    function handleDroppedFiles(files: File[], entry: TextureParameter): void {
        if (files.length === 0) return;

        uploadingTextureParameter.current = entry;

        const reader = new FileReader();
        const filename = files[0].name;
        reader.onload = (e: ProgressEvent<FileReader>) =>
            handleUploadTexture(e.target.result as ArrayBuffer, filename);
        reader.readAsArrayBuffer(files[0]);
    }

    function handleUploadTexture(buffer: ArrayBuffer, filename: string): void {
        const thumbnailBlob = new Blob([buffer], {
            type: extensionToMimeType(filename),
        });
        console.log(URL.createObjectURL(thumbnailBlob));
        setOverrideTextureThumbnail(URL.createObjectURL(thumbnailBlob));

        setTimeout(() => {
            const ptr = window.lys._malloc(buffer.byteLength);
            window.lys.HEAPU8.set(new Uint8Array(buffer), ptr);
            const textureId = globalThis.lys.createTexture(
                filename,
                ptr,
                buffer.byteLength,
            );

            if (uploadingTextureParameter.current) {
                setMaterialParameter(
                    uploadingTextureParameter.current.name,
                    textureId,
                );
            }
        }, 1);
    }

    function paramToUI(entry: AnyParameter, index: number) {
        switch (entry.type) {
            case "double": {
                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            paddingBottom: 1,
                            paddingRight: 1,
                            paddingLeft: 1,
                        }}
                        key={index}
                    >
                        <Grid xs="auto">{entry.name}</Grid>

                        <Grid xs sx={{ paddingLeft: 1, paddingRight: 1 }}>
                            <Slider
                                size="small"
                                style={{ width: "100%" }}
                                value={entry.value * 50} // Display either internal or external value
                                onChange={(_e, newValue: number) => {
                                    setMaterialParameter(
                                        entry.name,
                                        newValue / 50,
                                    );
                                }}
                                aria-labelledby="input-slider"
                                sx={{
                                    MozUserSelect: "none",
                                    WebkitUserSelect: "none",
                                    msUserSelect: "none",
                                }}
                            />
                        </Grid>

                        <Grid xs={4} sx={{ height: "32px" }}>
                            <TextField
                                margin="none"
                                variant="outlined"
                                type="number"
                                size="small"
                                fullWidth
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                    height: "32px",
                                    "& .MuiInputBase-root": {
                                        height: "32px",
                                        padding: "0px 8px"  // adjust padding if necessary
                                    },
                                    "& .MuiInputBase-input": {
                                        height: "32px",
                                        padding: "0px",  // remove padding to fit exactly
                                        lineHeight: "32px"  // centers text vertically
                                    }
                                }}
                                value={entry.value}
                                onChange={(e) => {
                                    setMaterialParameter(entry.name, parseFloat(e.target.value));
                                }}
                                onBlur={(e) =>
                                    setMaterialParameter(entry.name, parseFloat(e.target.value))
                                }
                                inputProps={{
                                    step: 0.1,
                                    min: 0,
                                    max: 2,
                                    inputMode: "decimal",
                                    type: "number",
                                    "aria-labelledby": "input-slider",
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            }
            case "color": {
                const value = entry.value as string;
                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            paddingBottom: 1,
                            paddingRight: 1,
                            paddingLeft: 1,
                        }}
                        key={index}
                    >
                        <Grid key={entry.name} xs>
                            {entry.name}
                        </Grid>
                        <Grid
                            xs={4}
                            style={backgroundColor(value)}
                            onClick={(e) => {
                                (
                                    e.currentTarget.childNodes[0] as HTMLElement
                                )?.focus();
                                (
                                    e.currentTarget.childNodes[0] as HTMLElement
                                )?.click();
                            }}
                        >
                            <input
                                style={{ opacity: 0, height: '30px' }}
                                type="color"
                                value={value}
                                onInput={(e) =>
                                    throttledSetMaterialParameter(
                                        entry.name,
                                        e.currentTarget.value,
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                );
            }
            case "texture": {
                const selected: boolean = entry.value > -1;
                return (
                    <FileDropZone
                        key={index}
                        onFilesDrop={(files) =>
                            handleDroppedFiles(files, entry)
                        }
                        dropAllowed={access === "can edit"}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                paddingBottom: 1,
                                paddingRight: 1,
                                paddingLeft: 1,
                            }}
                        >
                            <Grid key={entry.name} xs>
                                {entry.name}
                            </Grid>
                            <Grid xs={4}>
                                {selected ? (
                                    <StyledBox sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                                        <ThumbnailWithRetry
                                            src={
                                                uploadingTextureParameter.current?.name === entry.name &&
                                                    overrideTextureThumbnail
                                                    ? overrideTextureThumbnail
                                                    : `/api/scenes/${sceneId}/textures/${entry.value}/thumbnail`
                                            }
                                            onClick={() => handleClickTexture(entry)}
                                        />
                                        <IconButton
                                            onClick={() => handleUnselectTexture(entry)}
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </StyledBox>
                                ) : (
                                    <Button
                                        sx={{ flexGrow: 0, textTransform: 'none' }}
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleClickTexture(entry)}
                                    >
                                        Select/drop
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </FileDropZone>
                );
            }
        }
    }

    return (
        <Box>
            {selectedMaterialProperties[0] && (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: 1 }}
                >
                    <Grid xs={2} sx={{ paddingBottom: 1 }}>
                        Name
                    </Grid>
                    <Grid xs={10} sx={{ paddingBottom: 1 }}>
                        <TextField
                            id="standard-basic"
                            value={name}
                            fullWidth
                            variant="outlined"
                            size="small"
                            disabled={!selectedMaterialProperties[0]}
                            hiddenLabel
                            InputLabelProps={
                                selectedMaterialProperties[0] && {
                                    shrink: true,
                                }
                            }
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            onChange={handleNameChange}
                            onBlur={(e) =>
                                globalThis.lys.setMaterialName(
                                    selectedMaterialProperties[0].id,
                                    e.currentTarget.value,
                                    true,
                                )
                            }
                            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === "Enter")
                                    (e.target as HTMLInputElement).blur();
                            }}
                        />
                    </Grid>

                    <Grid xs={8}>Type</Grid>
                    <Grid xs={4}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) => setMaterialType(e.target.value)}
                            size="small"
                            value={
                                selectedMaterialProperties[0] &&
                                selectedMaterialProperties[0].type
                            }
                            inputProps={{ "aria-label": "Without label" }}
                            style={{ width: "100%" }}
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                        >
                            {materialTypes &&
                                materialTypes.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
            )}

            <FileImporter
                ref={fileImporter}
                onFileRead={(buffer, filename) =>
                    handleUploadTexture(buffer, filename)
                }
            />
            {selectedMaterialProperties[0] &&
                selectedMaterialProperties[0].parameters.map(
                    (entry: AnyParameter, index: number) =>
                        paramToUI(entry, index),
                )}
        </Box>
    );
}
