import React, { useState, useEffect, useRef } from 'react';
import { Box, Chip, Paper, Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, List, ListItem, ImageList, ImageListItemBar, ImageListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FButton from '../FButton';
import { useComponentSize } from '../../utils/use-component-size';

const SelectableAccordion = ({ title, items, allSelected, someSelected, handleSelectAll, handleItemChange, count, disabled }) => (
    <Accordion disableGutters sx={{ margin: 0 }} >
        <Paper elevation={1} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: '0 16px' }}>
            
                {title}: {count} 
        
           
            </AccordionSummary>
        </Paper>
        <AccordionDetails sx={{ padding: '0 0 0 24px' }}>
            <List dense disablePadding>
                {items.map((item) => (
                    <ListItem key={item.id} disableGutters sx={{ padding: '8px 0' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    sx={{ padding: 0 }}
                                    checked={item.selected}
                                    onChange={(e) => handleItemChange(item.id, e.target.checked)}
                                />
                            }
                            label={item.label}
                            componentsProps={{ typography: { sx: { ml: 1 } } }}
                        />
                    </ListItem>
                ))}
            </List>
        </AccordionDetails>
    </Accordion>
);

const ShotConfigurator = () => {
    const [forceUpdate, setForceUpdate] = useState(0);
    const [imageListItems, setImageListItems] = useState([]);
    const [selections, setSelections] = useState({
        cameras: [],
        environments: [],
        imageSizes: [
            { id: 'realtime', label: 'Real time view', selected: true },
            { id: 'social_square', label: 'Social Square (1080x1080)', selected: false },
            { id: 'social_story', label: 'Social Story (1080x1920)', selected: false },
            { id: 'web_banner', label: 'Web Banner (1600x900)', selected: false },
            { id: 'presentation', label: 'Presentation (1920x1080)', selected: false },
            { id: 'print_small', label: 'Print Small (1200x1600)', selected: false },
            { id: 'print_large', label: 'Print Large (2400x3600)', selected: false },
        ],
        materialVariants: [
            { id: 'default', label: 'default', selected: true },
            { id: 'metallic_silver', label: 'Metallic Silver', selected: false },
            { id: 'matte_black', label: 'Matte Black', selected: false },
            { id: 'glossy_white', label: 'Glossy White', selected: false },
            { id: 'satin_gold', label: 'Satin Gold', selected: false },
            { id: 'brushed_bronze', label: 'Brushed Bronze', selected: false },
            { id: 'carbon_black', label: 'Carbon Black', selected: false },
            { id: 'midnight_blue', label: 'Midnight Blue', selected: false },
        ],
    });

    const imageContainerRef = useRef(null);
    const { width: imageContainerWidth } = useComponentSize(imageContainerRef);
    const [imageColumns, setImageColumns] = useState(1);

    useEffect(() => {
        setImageColumns(Math.max(1, Math.floor(imageContainerWidth / 140)));
    }, [imageContainerWidth]);

    const fetchCameras = async () => {
        const cameraIds = globalThis.lys.getAllCameraIds();
        const activeCamera = globalThis.lys.getActiveCameraId();
        const cameras = cameraIds.map((id) => {
            const node = globalThis.lys.getNodeById(id);
            return { id: node.getId(), label: node.getName(), selected: node.getId() === activeCamera };
        });
        setSelections((prevSelections) => ({
            ...prevSelections,
            cameras,
        }));
    };

    const fetchEnvironments = async () => {
        const environmentIds = globalThis.lys.getAllEnvironmentIds();

        // if environmentIds array is length zero, then add a single default environment
        if (environmentIds.length === 0) {
            const environments = [{ id: -1, label: 'Default', selected: true }];
            setSelections((prevSelections) => ({
                ...prevSelections,
                environments,
            }));
        } else {
            const activeEnvironment = globalThis.lys.getActiveEnvironmentIndex();
            const environments = environmentIds.map((id) => {
                const imageTexture = globalThis.lys.getHdriTextureById(id);
                return { id: imageTexture.getId(), label: imageTexture.getName(), selected: imageTexture.getId() === activeEnvironment };
            });
            setSelections((prevSelections) => ({
                ...prevSelections,
                environments,
            }));
        }
    };

    useEffect(() => {
        fetchCameras();
        fetchEnvironments();
    }, [forceUpdate]);

    const handleSelectAll = (section, checked) => {
        setSelections((prevSelections) => ({
            ...prevSelections,
            [section]: prevSelections[section].map((item) => ({ ...item, selected: checked })),
        }));
    };

    const handleItemChange = (section, itemId, checked) => {
        setSelections((prevSelections) => ({
            ...prevSelections,
            [section]: prevSelections[section].map((item) =>
                item.id === itemId ? { ...item, selected: checked } : item
            ),
        }));
    };

    const isAllSelected = (section) => selections[section].every((item) => item.selected);
    const isSomeSelected = (section) => selections[section].some((item) => item.selected);

    useEffect(() => {
        generateImageListItems();
    }, [selections]);

    const generateImageListItems = () => {
        const combinations = [];
        
        selections.cameras.filter((c) => c.selected).forEach((camera) => {
            selections.environments.filter((e) => e.selected).forEach((environment) => {
                selections.imageSizes.filter((s) => s.selected).forEach((imageSize) => {
                    selections.materialVariants.filter((m) => m.selected).forEach((material) => {
                        const id = `${camera.id}-${environment.id}-${imageSize.id}-${material.id}`;

                        // Queue render for later retrieval
                        globalThis.lys.addToRenderQueue(id, camera.id, environment.id, 160, 160, 64);
                        
                        // Initially, set a placeholder or loading state
                        combinations.push({
                            id,
                            src: null, // initially null to indicate loading
                            alt: `${camera.label} - ${environment.label} - ${imageSize.label} - ${material.label}`,
                            retrieved: false, // track if image has been retrieved
                            cameraId: camera.id,
                            environmentId: environment.id,
                        });
                    });
                });
            });
        });

        setImageListItems(combinations);
    };

    useEffect(() => {
        // imageReady called from C++ when thumbnail with id is ready
        globalThis.imageReady = async (id) => {
            const renderFrame = await window.lys.getCurrentImage();
            const src = URL.createObjectURL(renderFrame.full);
            setImageListItems((prevItems) =>
                prevItems.map((item) => (item.id === id ? { ...item, src, retrieved: true } : item))
            );
        };

        return () => {
            delete globalThis.imageReady;
            // TODO: revoke object urls?
            // URL.revokeObjectURL(dataUrl)
        };
    }, []);

    const handleSetSceneState = (cameraId, environmentId) => {
        globalThis.lys.setActiveCamera(cameraId, false);
        globalThis.lys.setActiveEnvironmentIndex(environmentId, false);
    };

    const getSelectedCount = (section) =>
        selections[section].filter((item) => item.selected).length;
    

    const areImagesLoading = imageListItems.some(item => item.src == null);

    return (
        <div style={{ position: 'relative', paddingBottom: '60px' }}>
            <SelectableAccordion
                title={`Cameras`}
                count={getSelectedCount('cameras')}
                items={selections.cameras}
                allSelected={isAllSelected('cameras')}
                someSelected={isSomeSelected('cameras')}
                handleSelectAll={(checked) => handleSelectAll('cameras', checked)}
                handleItemChange={(itemId, checked) => handleItemChange('cameras', itemId, checked)}
                disabled={areImagesLoading}
            />
            <SelectableAccordion
                title={`Environments`}
                count={getSelectedCount('environments')}
                items={selections.environments}
                allSelected={isAllSelected('environments')}
                someSelected={isSomeSelected('environments')}
                handleSelectAll={(checked) => handleSelectAll('environments', checked)}
                handleItemChange={(itemId, checked) => handleItemChange('environments', itemId, checked)}
                disabled={areImagesLoading}
            />
            <SelectableAccordion
                title={`Image sizes`}
                count={getSelectedCount('imageSizes')}
                items={selections.imageSizes}
                allSelected={isAllSelected('imageSizes')}
                someSelected={isSomeSelected('imageSizes')}
                handleSelectAll={(checked) => handleSelectAll('imageSizes', checked)}
                handleItemChange={(itemId, checked) => handleItemChange('imageSizes', itemId, checked)}
                disabled={areImagesLoading}
            />
            <SelectableAccordion
                title={`Material variants`}
                count={getSelectedCount('materialVariants')}
                items={selections.materialVariants}
                allSelected={isAllSelected('materialVariants')}
                someSelected={isSomeSelected('materialVariants')}
                handleSelectAll={(checked) => handleSelectAll('materialVariants', checked)}
                handleItemChange={(itemId, checked) => handleItemChange('materialVariants', itemId, checked)}
                disabled={areImagesLoading}
            />

            <div ref={imageContainerRef}>
                <ImageList cols={imageColumns} gap={12} sx={{ m:1, boxSizing: "border-box" }} style={{ gridTemplateColumns: `repeat(${imageColumns}, minmax(0, 1fr))` }}>
                    {imageListItems.map((item) => (
                        <ImageListItem
                            key={item.id}
                            onClick={() => handleSetSceneState(item.cameraId, item.environmentId)}
                        >
                            <div style={{ display: "flex", flexDirection: "column", }}>
                                <img
                           
                                    src={item.src || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="}
                                    alt={item.alt}
                                    loading="lazy"
                                    style={{
                                        flex: 1,
                                        borderRadius: "5px",
                                        zIndex: 2,
                                    }}
                                />
                                {item.alt &&
                                    <ImageListItemBar
                                        title={"item.alt"}
                                        position="below"
                                        sx={{
                                            flex: 1,
                                            backgroundColor: "background.label",
                                            borderRadius: "0 0 5px 5px",
                                            marginTop: "-5px",
                                            padding: "10px 5px 0px 8px",
                                            zIndex: 1,
                                        }}
                                    />
                                }
                            </div>
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>

            <FButton
                style={{
                    position: 'fixed',
                    bottom: '16px',
                    right: '16px',
                }}
                onClick={() => alert('Rendering...')}
            >
                Render
            </FButton>
        </div>
    );
};

export default ShotConfigurator;
