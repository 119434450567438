import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { AuthenticatedUser, isAuthenticatedUser, User } from "../../utils/user";
import { ChatMessage } from "../../utils/websocket-chat-hook";

export type ChatProps = {
    sceneId: string;
    user: User | AuthenticatedUser;
    cameraPosition: string;
    language: string;
    setLanguage: (language: string) => void;
    setNewChatMessagesCounter: Dispatch<SetStateAction<number>>;
    messages: ChatMessage[];
    sendMessage: (message: ChatMessage) => void;
    error?: string;
    isConnected: boolean;
}

function isWrittenByMe(message: ChatMessage, me: User | AuthenticatedUser): boolean {
    if ("id" in me && !!message.userId) {
        return me.id === message.userId;
    }
    return me.name === message.userName;
}

export default function Chat({ user, sceneId, 
    cameraPosition, language, setLanguage, 
    messages, sendMessage, error, isConnected }: ChatProps) {

    const [currentMessage, setCurrentMessage] = useState("");
    const lastMessageRef = useRef(null);

    function handleKeyUp(event: KeyboardEvent) {
        if (event.key === "Enter" && currentMessage.trim() !== "") {
            const newMessage = {
                type: "chat",
                userId: isAuthenticatedUser(user) ? user.id : null,
                userName: user.name,
                sceneId,
                contents: currentMessage,
                cameraPosition
            };
            handleSendMessage(newMessage);
            setCurrentMessage("");
        }
    }

    const handleSendMessage = (message: ChatMessage) => {
        sendMessage(message);
    };

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }} padding={1}>
                <FormControl>
                    <InputLabel id="translate-chat-helper-label">Translate</InputLabel>
                    <Select size="medium" label="Translate" labelId="translate-chat-helper-label" onChange={(e) => setLanguage(e.target.value)} value={language} disabled={!isConnected}>
                        <MenuItem value=""><em>Do not translate</em></MenuItem>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="es">Español</MenuItem>
                        <MenuItem value="de">Deutszch</MenuItem>
                    </Select>
                </FormControl>
                <Grid container sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <Stack spacing={2} flexGrow={1}>
                        {messages.map((message, index) => (
                            <Card
                                key={index}
                                ref={index === messages.length - 1 ? lastMessageRef : null}
                                sx={{ color: '#fff', borderRadius: '8px', backgroundColor: isWrittenByMe(message, user) ? "#0288d1" : "#555", alignSelf: isWrittenByMe(message, user) ? "end" : "start" }}>
                                <CardContent>
                                    <Typography sx={{ color: '#ddd' }}>
                                        {message.userName}
                                    </Typography>
                                    <Typography variant="body1">
                                        {message.contents}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                {error && <Typography variant="body1" color="error">{error}</Typography>}
                <TextField label="Message"
                    name="message"
                    fullWidth
                    disabled={!isConnected}
                    size="medium"
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    onKeyUp={handleKeyUp}
                />
            </Box>
        </>
    );
} 