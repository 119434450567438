// StyledBox.js
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => {


  // Determine the border color based on the state
  const borderColor =  theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.23)'
    : 'rgba(255, 255, 255, 0.23)';

  return {
    border: `1px solid ${borderColor}`,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '1.4375em',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Hover effect
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
    // Focus effect
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
      boxShadow: `${theme.palette.primary.main} 0 0 0 2px`,
    },
  };
});

export default StyledBox;
