import { createContext, useContext, useState, Dispatch, SetStateAction, ReactNode } from "react";

export enum SceneTabMode {
  scene,
  materials,
  envs,
  cameras,
  images,
  shots,
  renders,
  chat,
}

type SceneTabModeContextType = {
    sceneTabMode: SceneTabMode;
    setSceneTabMode: Dispatch<SetStateAction<SceneTabMode>>;
};

const SceneTabModeContext = createContext<SceneTabModeContextType | undefined>(undefined);

type SceneTabModeProviderProps = {
  children: ReactNode;
  initialMode?: SceneTabMode;  // Add initialMode as an optional prop
};

export const SceneTabModeProvider = ({ children, initialMode }: SceneTabModeProviderProps) => {
  const [sceneTabMode, setSceneTabMode] = useState<SceneTabMode>(initialMode || SceneTabMode.scene);  // Initialize with initialMode or default to SceneTabMode.scene

  return (
    <SceneTabModeContext.Provider value={{ sceneTabMode, setSceneTabMode }}>
      {children}
    </SceneTabModeContext.Provider>
  );
};

export const useSceneTabMode = () => {
  const context = useContext(SceneTabModeContext);
  if (!context) {
    throw new Error("useMode must be used within a SceneTabModeProvider");
  }
  return context;
};
