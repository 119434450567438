import { useState, useRef, useEffect, SyntheticEvent } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

interface NumberInputProps {
    value: number;                // The current value of the input, which is a number
    setValue: React.Dispatch<React.SetStateAction<number>>;  // Function to set the new value
    label?: string;

    disabled?: boolean;           // Optional, indicates whether the input is disabled
    adornment?: string;           // Optional, adornment text to be displayed in the InputAdornment   
    precision?: number;           // Optional, precision for rounding the value (default is 4)
    step?: number;                // Optional, step for incrementing the value (default is 1)
    fullWidth?: boolean;          // Optional, indicates whether the input should be full width
}

function NumberInput({ disabled, label, adornment = "", value, setValue, precision = 4, step = 1, fullWidth } : NumberInputProps ) {
    const [dragging, setDragging] = useState(false);
    const startX = useRef(null);
    const [startValue, setStartValue] = useState(0);
    const [roundedValue, setRoundedValue] = useState<string>("");
    const [multiplierTextPos, setMultiplierTextPos] = useState({ x: 0, y: 0 });
    const [multiplier, setMultiplier] = useState(1);
    const inputRef = useRef(null);

    const sensitivity : number = 0.01; 

    const handleMouseDown = (e) => {
        if (!disabled) {
            setDragging(true);
            inputRef.current.style.pointerEvents = 'none';

            startX.current = e.clientX;
            setStartValue(Number(value));
            document.body.style.cursor = 'ew-resize'; 
            document.body.style.userSelect = 'none'; 
        }
    };

    const handleMouseMove = (e) => {
        e.preventDefault(); 
        if (dragging) {
            let currentMultiplier = 1;

            if (e.shiftKey) {
                currentMultiplier = 10; // 10x step when Shift is pressed
            } else if (e.ctrlKey) {
                currentMultiplier = 0.1; // 0.1x step when Ctrl is pressed
            }

            setMultiplier(currentMultiplier);

            const deltaX = e.clientX - startX.current;
            const increment : number = deltaX * step * sensitivity * currentMultiplier; 

            setValue((prevValue) => (prevValue + increment));

            setMultiplierTextPos({ x: e.clientX, y: e.clientY });
            startX.current = e.clientX;
        }
    };

    const handleMouseUp = (e) => {

        setDragging(false);
        document.body.style.cursor = 'default'; 
        document.body.style.userSelect = '';

        inputRef.current.style.pointerEvents= 'auto'; 

    };

    useEffect(() => {
        if (value == undefined) return;
       
        if (!isNaN(value))
        {
           setRoundedValue((Math.round(value * 10 ** precision) / 10 ** precision).toString());
        }
    }, [value]);

    useEffect(() => {
        if (dragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        } else {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [dragging]);

    return (
        <>
            <TextField
                label={label}
                fullWidth={fullWidth}
                inputRef={inputRef}
                onMouseDown={handleMouseDown}
                onChange={(e) => {
                    setRoundedValue(e.target.value);
                    if (e.target.value !== "" && !isNaN(Number(e.target.value)) && e.target.value !== "-" && e.target.value !== "-0") {
                        setValue(Number(e.target.value));
                    }
                }}
                value={roundedValue}
                sx={{
                    padding: 0, 
                    m: 0, 
                    '& legend': { display: 'none' }, 
                    '& fieldset': { top: 0 },
                    '& .MuiInputBase-input': {
                        cursor: dragging ? 'ew-resize' : 'ew-resize', 
                        MozAppearance: 'textfield',
                        '&::-webkit-outer-spin-button': {
                            display: 'none',
                        },
                        '&::-webkit-inner-spin-button': {
                            display: 'none',
                        },
                    },
                }}
                type="number"
                variant="standard"
                InputProps={{
                    readOnly: dragging, 
                    inputProps: {
                        inputMode: 'decimal',
                        step: step,
                    },
                    endAdornment: adornment ? (
                        <InputAdornment position="end">{adornment}</InputAdornment>
                    ) : null
                }}
            />
            {dragging && (
                <div
                    style={{
                        position: 'fixed',
                        top: multiplierTextPos.y + 20,
                        left: multiplierTextPos.x + 10,
                        background: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        padding: '2px 4px',
                        borderRadius: '4px',
                        pointerEvents: 'none',
                        fontSize: '12px'
                    }}
                >
                    {multiplier}x
                </div>
            )}
        </>
    );
}

export default NumberInput;
