import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import FButton from "../FButton";

export type AskDeleteDialogProps = {
  noun: string,
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  onClose: () => void
}

export function AskDeleteDialog({ noun, open, onCancel, onConfirm, onClose }: AskDeleteDialogProps) {

  return (<Dialog open={open} onClose={onClose}>
    <DialogTitle>Delete {noun}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete this {noun}?
      </DialogContentText>
      <DialogActions>
        <FButton color='primary' onClick={onCancel}>Cancel</FButton>
        <FButton color='error' onClick={onConfirm}>Delete</FButton>
      </DialogActions>
    </DialogContent>
  </Dialog>
  )

}
      