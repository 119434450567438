// SceneTreeViewer.tsx

import React, { useState, useEffect } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box, Typography, IconButton, Tooltip, } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import CameraIcon from '@mui/icons-material/CameraAltOutlined';
import { useTheme } from '@mui/material/styles';
import BoxPlusIcon from '@mui/icons-material/AddBoxOutlined';
import BoxMinusIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import BoxIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LockIcon from '@mui/icons-material/Lock';
import CubeIcon from '@mui-extra/icons/CubeIcon';
import { actions, ActionKey, ActionContext } from '../actions';
import ActionMenu from '../ActionMenu';
import { VisibilityOff } from '@mui/icons-material';

const nodeTypeToIcon = (typeName: string) => {
    switch (typeName) {
        case "node":
            return BoxIcon;
        case "camera":
            return CameraIcon;
        case "trimesh":
            return CubeIcon;
        default:
            return undefined;
    }
};

interface Props {
    search: string;
    selectedNodeIds: number[];
    setSelectedNodeIds: (ids: number[]) => void;
    rootNodeId: number;
    forceUpdate: number;
    extraButton?: JSX.Element; // Optional extra button
    actionsList: ActionKey[]; // List of action keys
    activeCamera?: number | null; // Optional active camera
    setActiveCamera?: (cameraId: number | null) => void; // Optional setter for active camera
}

interface TreeNode {
    id: number;
    name: string;
    type: string;
    materialName?: string;
    children: TreeNode[];
    isLocked?: boolean;
    isHidden?: boolean;
}

const SceneTreeViewer = ({
    search,
    selectedNodeIds,
    setSelectedNodeIds,
    rootNodeId,
    forceUpdate,
    extraButton,
    actionsList,
    activeCamera,
    setActiveCamera
}: Props) => {
    const [tree, setTree] = useState<TreeNode[]>([]);
    const [draggedItems, setDraggedItems] = useState<number[] | null>(null);
    const [dragTarget, setDragTarget] = useState<number | null>(null);
    const [editingNodeId, setEditingNodeId] = useState<number | null>(null);
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [isDraggingOverRoot, setIsDraggingOverRoot] = useState(false);
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        nodeId: number | null;
    } | null>(null);

    // sync expanded nodes with selectedNodeIds
    useEffect(() => {
        const getParentIds = (nodeId: number): string[] => {
            const node = globalThis.lys.getNodeById(nodeId);
            if (!node) return [];
            const parents: string[] = [];
            let parent = node.getParent();
            while (parent && parent.getId() !== rootNodeId) {
                parents.push(parent.getId().toString());
                parent = parent.getParent();
            }
            return parents;
        };

        let parentsToAdd: string[] = [];
        selectedNodeIds.forEach((nodeId) => {
            const parentIds = getParentIds(nodeId);
            parentsToAdd = parentsToAdd.concat(parentIds);
        });

        // Remove duplicates and merge with existing expandedNodes
        const newExpandedNodes = Array.from(
            new Set([...expandedNodes, ...parentsToAdd]),
        );

        // Only update if there are new nodes to add
        if (newExpandedNodes.length !== expandedNodes.length) {
            setExpandedNodes(newExpandedNodes);
        }
    }, [selectedNodeIds]);

    const theme = useTheme();

    const handleBlankAreaClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            setSelectedNodeIds([]); // Clear selection when clicking on blank area
        }
    };

    const handleDragStart = (node: TreeNode, event: React.DragEvent) => {
        event.stopPropagation();
        event.dataTransfer.effectAllowed = "move";
        //add the node id to the selected node ids
        setDraggedItems([...new Set([...selectedNodeIds, node.id])]);

        console.log("handleDragStart", node.id, [
            ...new Set([...selectedNodeIds, node.id]),
        ]);

        setSelectedNodeIds([...new Set([...selectedNodeIds, node.id])]);
    };

    const handleDrop = (targetNode: TreeNode, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (!draggedItems) return;

        // remove targetNode.id from selectedNodeIds
        const filteredDraggedItems = draggedItems.filter(
            (id) => id !== targetNode.id,
        );

        if (targetNode.type === "node") {
            console.log(targetNode.type, filteredDraggedItems);
            filteredDraggedItems.forEach((id) => {
                globalThis.lys.reparentNode(targetNode.id, id, true);
                setExpandedNodes([...expandedNodes, targetNode.id.toString()]);
            });
        } else {
            console.log(targetNode.type, filteredDraggedItems);
            const newFolderId = globalThis.lys.createNodeGenId("New Group");
            const parent = globalThis.lys
                .getNodeById(targetNode.id)
                .getParent()
                .getId();

            globalThis.lys.reparentNode(parent, newFolderId, true); // Reparent target node
            globalThis.lys.reparentNode(newFolderId, targetNode.id, true); // Reparent target node
            setExpandedNodes([...expandedNodes, newFolderId.toString()]);

            filteredDraggedItems.forEach((id) => {
                globalThis.lys.reparentNode(newFolderId, id, true); // Reparent dragged node
            });
        }

        setDraggedItems(null);
        setDragTarget(null); // Clear drag target after drop
        updateFromLys(); // Refresh the scene tree after the reparenting action
    };

    const handleDropToRoot = (event: React.DragEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setIsDraggingOverRoot(false);
        if (!draggedItems || event.target !== event.currentTarget) return;

        console.log(draggedItems);

        draggedItems.forEach((id) => {
            globalThis.lys.reparentNode(rootNodeId, id, true);
        });

        setDraggedItems(null);
        setDragTarget(null); // Clear drag target after drop
        updateFromLys(); // Refresh the scene tree after the reparenting action
    };

    const handleDragOverRoot = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDraggingOverRoot(true);
    };

    const handleDragLeaveRoot = () => {
        setIsDraggingOverRoot(false);
    };

    const handleDragOverNode = (nodeId: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "move"; // Show move as the intended effect
        setDragTarget(nodeId); // Set the current drag target
    };

    const handleDragLeaveNode = () => {
        setDragTarget(null); // Clear the current drag target when leaving a node
    };

    const handleNodesSelected = (
        event: React.SyntheticEvent,
        itemIds: string[] | string,
    ) => {
        let selectedIds = Array.isArray(itemIds)
            ? itemIds.map(Number)
            : [Number(itemIds)];

        if (setActiveCamera && selectedIds.length == 1) {
            const node = globalThis.lys.getNodeById(selectedIds[0]);
            if (node && node.getType() === "camera") {
                globalThis.lys.setActiveCamera(selectedIds[0], true);
                setActiveCamera(selectedIds[0]);
            }
        }

        setSelectedNodeIds(selectedIds);
    };

    const buildTree = (nodeId: number): TreeNode => {
        const node = globalThis.lys.getNodeById(nodeId);
        const name = node.getName();
        const type = node.getType();
        const isLocked = node.isLocked();
        const isHidden = node.isHidden();
        const mat = node.getMaterial();
        const materialName = mat ? mat.getName() : undefined;
        const childrenHandle = node.getChildren();

        const children: TreeNode[] = [];
        const childrenCount = childrenHandle.size();
        for (let i = 0; i < childrenCount; i++) {
            const childNodeId = childrenHandle.get(i).getId();
            children.push(buildTree(childNodeId));
        }

        return {
            id: nodeId,
            name,
            type,
            materialName,
            children,
            isLocked,
            isHidden
        };
    };

    const updateFromLys = () => {
        if (!globalThis.lys) return;

        setActiveCamera?.(globalThis.lys.getActiveCameraId());     

        const rootNode = globalThis.lys.getNodeById(rootNodeId);
        const childrenHandle = rootNode.getChildren();
        const children: TreeNode[] = [];
        const childrenCount = childrenHandle.size();
        for (let i = 0; i < childrenCount; i++) {
            const childNodeId = childrenHandle.get(i).getId();
            children.push(buildTree(childNodeId));
        }
        setTree(children);
    };

    useEffect(() => {
        updateFromLys();
    }, [forceUpdate]);

    const handleNodeNameSubmit = (nodeId: number, name: string) => {
        globalThis.lys.setNodeName(nodeId, name, true);
        setEditingNodeId(null); // Exit editing mode
    };

    const handleKeyPress = (e, nodeId: number, name: string) => {
        if (e.key === "Enter") {
            setEditingNodeId(null);
            handleNodeNameSubmit(nodeId, name); // Trigger rename on Enter
            e.preventDefault(); // Prevent any further default action for Enter
        } else if (e.key === "Escape") {
            setEditingNodeId(null); // Exit editing mode on Escape
        }
    };

    const handleEditNode = (node: TreeNode) => {
        setEditingNodeId(node.id);
    };

    const lowerSearch = search.toLowerCase();
    // Function to filter and render tree items recursively based on the search term
    const filterTree = (node: TreeNode): TreeNode | null => {
        const nodeNameLower = node.name.toLowerCase();
        const nodeTypeLower = node.type.toLowerCase();
        const nodeMaterialLower = node.materialName?.toLowerCase() ?? "";

        // Check if the current node matches the search criteria
        const nodeMatches =
            nodeNameLower.includes(lowerSearch) ||
            nodeTypeLower.includes(lowerSearch) ||
            nodeMaterialLower.includes(lowerSearch);

        if (nodeMatches && (!node.children || node.children.length === 0)) {
            return node;
        }

        const filteredChildren =
            node.children
                ?.map(filterTree)
                .filter((child): child is TreeNode => child !== null) ?? [];

        if (nodeMatches || filteredChildren.length > 0) {
            return {
                ...node,
                children: filteredChildren,
            };
        }
        return null;
    };

    const filteredTree = tree
        .map((node) => filterTree(node))
        .filter((node): node is TreeNode => node !== null);

    const renderTreeItems = (node: TreeNode) => {
        const isDraggingOver = dragTarget === node.id;
        const IconComponent = nodeTypeToIcon(node.type); // Get the correct icon component

        const isSelected = selectedNodeIds.includes(node.id);

        const isActiveCameraNode = node.type === 'camera' && activeCamera === node.id;
    
        const handleRightClick = (event: React.MouseEvent, nodeId: number) => {
            setSelectedNodeIds([...new Set([...selectedNodeIds, nodeId])]);

            event.preventDefault(); // Prevent the default right-click behavior
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY - 4,
                        nodeId: nodeId, // Track the node on which right-click was performed
                    }
                    : null,
            );
        };

        return (
            <TreeItem
                draggable={editingNodeId !== node.id} // Disable dragging if the node is being edited
                onDragStart={(e) => handleDragStart(node, e)}
                onDrop={(e) => handleDrop(node, e)}
                onDragOver={(e) => handleDragOverNode(node.id, e)}
                onDragLeave={handleDragLeaveNode}
                key={node.id}
                itemId={node.id.toString()}
                slots={{
                    endIcon: IconComponent
                        ? () => (
                            <IconComponent
                                sx={{
                                    color: isActiveCameraNode ? theme.palette.primary.main : 'inherit',
                                }}
                            />
                        )
                        : undefined,
                }}
                label={
                    editingNodeId === node.id ? (
                        <InputBase
                            onClick={(e) => e.stopPropagation()} // Prevent the click event from affecting the parent TreeItem
                            defaultValue={node.name}
                            onBlur={(e) =>
                                handleNodeNameSubmit(node.id, e.target.value)
                            } // Handle name change on blur
                            onKeyDown={(e) => {
                                e.stopPropagation(); // Prevent the key event from bubbling to TreeView
                                handleKeyPress(
                                    e,
                                    node.id,
                                    (e.target as HTMLInputElement).value,
                                ); // Cast e.target to HTMLInputElement
                            }}
                            autoFocus
                            fullWidth
                            size="small"
                        />
                    ) : (
                        <Box
                            onContextMenu={(event) =>
                                handleRightClick(event, node.id)
                            }
                            onDoubleClick={() => handleEditNode(node)}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                pt: 0.5,
                                pb: 0.5,
                                pr: 0,
                                pl: 0,
                            }}
                        >
                            <Typography
                                flex="1 1 50%"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                            >
                                {node.name}
                            </Typography>

                            {node.materialName && (
                                <Typography
                                    flex="0 1 auto"
                                    color="inherit"
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    sx={{ ml: 1 }}
                                >
                                    {node.materialName}
                                </Typography>
                            )}

                            {node.isLocked && (
                                <LockIcon
                                    fontSize="small"
                                    sx={{
                                        ml: 1,
                                        color: theme.palette.grey[500],
                                    }}
                                />
                            )}
                            {node.isHidden && (
                                <VisibilityOff
                                    fontSize="small"
                                    sx={{ ml: 1, color: theme.palette.grey[500] }}
                                />
                            )}
                        </Box>
                    )
                }
                style={{
                    outlineColor: isDraggingOver
                        ? theme.palette.primary.main
                        : "none",
                    outlineWidth: isDraggingOver ? "2px" : "none",
                    outlineStyle: isDraggingOver ? "solid" : "none",
                    outlineOffset: isDraggingOver ? "-2px" : "none",
                }}
            >
                {node.children.map((childNode: TreeNode) =>
                    renderTreeItems(childNode),
                )}
            </TreeItem>
        );
    };

    const selectedItems = selectedNodeIds.map((id) => id.toString());

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
    ) => {
        setExpandedNodes(itemIds);
        event.stopPropagation();
    };

    const actionContext: ActionContext = {
        selectedNodeIds,
        setSelectedNodeIds,
        rootNodeId
    };

    // Define the menu actions
    const menuActions: (ActionKey | 'divider')[] = [
        'clone',
        'delete',
        'groupParts',
        'divider',
        'lockNode',
        'unlockNode',
        'divider',
        'hideNode',
        'showHiddenNode',
        'divider',
        'unlinkMaterial',
        'linkMaterial',
        'saveMaterialToLibrary',
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                boxSizing: "border-box",
            }}
        >
            <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                <SimpleTreeView
                    onClick={handleBlankAreaClick}
                    expansionTrigger="iconContainer"
                    selectedItems={selectedItems}
                    onSelectedItemsChange={handleNodesSelected}
                    multiSelect={true}
                    expandedItems={expandedNodes}
                    onExpandedItemsChange={handleExpandedItemsChange}
                    slots={{
                        expandIcon: BoxPlusIcon,
                        collapseIcon: BoxMinusIcon,
                    }}
                    sx={{
                        height: "100%",
                        flexGrow: 1,
                        overflow: "auto",
                        outline: isDraggingOverRoot
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                        outlineOffset: isDraggingOverRoot ? "-2px" : "none",
                    }}
                    onDragOver={handleDragOverRoot}
                    onDragLeave={handleDragLeaveRoot}
                    onDrop={handleDropToRoot}
                >
                    {filteredTree.map((node) => renderTreeItems(node))}
                </SimpleTreeView>
            </Box>

            {/* Fixed Row of Buttons with Tooltips */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '1em' }}>
                {/* Left-aligned extra button */}
                {extraButton && (
                    <Box sx={{ marginLeft: '1em' }}>
                        {extraButton}
                    </Box>
                )}

                {/* Right-aligned action buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                    {actionsList.map((actionKey) => {
                        const action = actions[actionKey];
                        const isDisabled = action.disabled ? action.disabled(actionContext) : false;
                        const labelText = typeof action.label === 'function' 
                        ? action.label(actionContext) // Call the function if label is a function
                        : action.label; // Use the string directly if label is a string
                        return (
                            <Tooltip title={labelText} arrow key={actionKey}>
                                <span>
                                    <IconButton
                                        aria-label={labelText}
                                        onClick={() => action.handler(actionContext)}
                                        disabled={isDisabled}
                                    >
                                        {action.icon ? <action.icon fontSize="small" /> : null}
                                    </IconButton>
                                </span>
                            </Tooltip>
                        );
                    })}
                </Box>
            </Box>

            {/* Context Menu for Right Click */}
            <ActionMenu
                open={contextMenu !== null}
                handleClose={() => setContextMenu(null)}
                location={
                    contextMenu !== null
                        ? [contextMenu.mouseX, contextMenu.mouseY]
                        : null
                }
                menuActions={menuActions}
                actionContext={actionContext}
            />
        </Box>
    );
};

export default SceneTreeViewer;
