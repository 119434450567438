import { ArrowCircleUp, Download, SearchOutlined, WidgetsOutlined } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Dialog, DialogContent, DialogTitle, IconButton, PaletteMode } from "@mui/material";
import Box from "@mui/material/Box";
import FButton from "../components/FButton";
import UserAvatar from "../components/toolbar/UserAvatar";
import { AuthenticatedUser, createTemporarySceneName } from "../utils/user";
import { createAndGotoNewScene } from '../utils/scene';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { ImportDataContext } from '../utils/import-data-context';
import { ModelFileImporter } from '../components/toolbar/ModelFileImporter';
import * as FileApi from '../services/file-api'
import { GridCloseIcon, GridDeleteIcon } from '@mui/x-data-grid';
import { AskDeleteDialog } from '../components/asset-library/AskDeleteDialog';
import ResizableContainer from '../components/ResizableContainer';
import FileGrid from '../components/asset-library/FileGrid';
import FTextField from '../components/FTextField';
import FolderTree from '../components/asset-library/FolderTree';
import StepFileDialog from './StepFileDialog';

export function changeExtension(destPath: string, extension: string) {
    return destPath.replace(/\.[^/.]+$/, extension);
}

export type FilesPageProps = 
    { 
        user: AuthenticatedUser, 
        onThemeModeChange: (mode: PaletteMode) => void
    }

export default function FilesPage({ user, onThemeModeChange }: FilesPageProps) {
    const navigate = useNavigate();
    const { '*': rest } = useParams();

    const [fileList, setFileList] = useState([]);
    const [folderList, setFolderList] = useState([]);
    const [folderTree, setFolderTree] = useState<FileApi.FSEntry[]>([]);
    const [askDeleteRender, setAskDeleteRender] = useState<boolean>(false);
    const [displayedRender, setDisplayedRender] = useState<FileApi.FSEntry | null>(null);

    const imageContainerRef = useRef(null);
    const modelImporter = useRef(null);

    const { setImportData } = useContext(ImportDataContext);


    useEffect(() => {
        FileApi.loadFolders().then(setFolderTree)
        FileApi.loadFilesForFolder(rest, false)
            .then((jsonData) => {
                let files = jsonData.filter((obj: FileApi.FSEntry) => obj.type !== "folder");
                if (files.every(f => f.type === "render")) {
                    files = files.sort((a, b) => a.modifiedAgo - b.modifiedAgo);
                }
                setFileList(files);
                setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === "folder"));
            })
    }, []);

    const onImportClick = () => {
        modelImporter.current.openDialog();
    };

    const onFileRead = (filename: string) => {
        createAndGotoNewScene(changeExtension(filename, ""), navigate);
    }

    const handleAddSceneClick = async () => {
        createAndGotoNewScene(createTemporarySceneName(), navigate);
    };

    function handleSelectedPath(path: string): void {
        navigate(`/files/${path}`, { replace: false });
    }

    const clickOnFolderIcon = async (id: string) => {
        onSelectNode(id);
        navigate(`/files/${id}`, { replace: true });
    };

    const onSelectNode = async (id): Promise<void> => {
        FileApi.loadFilesForFolder(id, false)
            .then((jsonData) => {
                let files = jsonData.filter((obj: FileApi.FSEntry) => obj.type !== "folder");
                if (files.every(f => f.type === "render")) {
                    files = files.sort((a, b) => a.modifiedAgo - b.modifiedAgo);
                }
                setFileList(files);
                setFolderList(jsonData.filter((obj: FileApi.FSEntry) => obj.type === "folder"));
            })
        handleSelectedPath(id);
    };

    const handleClickOnThumbnail = (e: React.MouseEvent, file: FileApi.FSEntry) => {
        e.stopPropagation();
        e.preventDefault();
        if (file.type === "scene") {
            navigate(`/scenes/${file.id}/edit`);
        } else if (file.type === "render") {
            setDisplayedRender(file);
        }
    };

    function downloadRender(file: FileApi.FSEntry) {
        const url = new URL(file.id, window.location.href);
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "render.png";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }

    function handleGotoScene(file: FileApi.FSEntry): void {
        if (file.sceneId) {
            navigate(`/scenes/${file.sceneId}/edit`);
            setDisplayedRender(null);
        }
    }

    function confirmedDeleteScene(id: string) {
        fetch(`/api/scenes/${id}`, {
            method: "DELETE",
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }).then(() => {
            onSelectNode(rest);
        })
    }

    function confirmedDeleteRender(id: string) {
        fetch(id, {
            method: "DELETE",
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setAskDeleteRender(null)
            setDisplayedRender(null);
        }).then(() => {
            onSelectNode(rest);
        })
    }

    
    function confirmedDeleteMaterial(id: string) {
        fetch(`/api/materials/${id}`, {
            method: "DELETE",
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }).then(() => {
            onSelectNode(rest);
        })
    }

    return (
        <>
            <Dialog fullScreen open={!!displayedRender} onClose={() => setDisplayedRender(null)} >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setDisplayedRender(null)}
                        aria-label="close"
                        title="Close"
                    >
                        <GridCloseIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => downloadRender(displayedRender)}
                        title="Download"
                        aria-label="download render">
                        <Download /></IconButton>
                    <IconButton
                        onClick={() => handleGotoScene(displayedRender)}
                        size="small"
                        title="Go to scene"
                        aria-label="Go to scene">
                        <WidgetsOutlined />
                    </IconButton>
                    <IconButton
                        onClick={() => setAskDeleteRender(true)}
                        size="small"
                        title="Delete render"
                        aria-label="Delete render">
                        <GridDeleteIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={displayedRender?.id} />
                </DialogContent>
            </Dialog >
            <AskDeleteDialog
                noun="Render"
                open={askDeleteRender}
                onCancel={() => setAskDeleteRender(null)}
                onConfirm={() => confirmedDeleteRender(displayedRender.id)}
                onClose={() => setAskDeleteRender(null)}
            />
            <main
                style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                }}
            >
                <ResizableContainer
                    defaultSizes={[0.25, 0.85]}
                    orientation={"horizontal"}
                    divider={false}
                >
                    <Box
                        style={{
                            display: "flex",
                            gap: "16px",
                            height: "100%",
                            width: "100%",
                            flexDirection: "column",
                            padding: "16px",
                        }}
                    >
                        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                            <h1 style={{ margin: 0, padding: "0 8px" }}>Figurement</h1>
                        </div>
                        <FolderTree folderTree={folderTree} selectedNodeId={rest} onSelectNode={onSelectNode} />
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            height: "100%",
                            width: "100%",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                padding: "16px 16px 16px 0",
                                gap: "16px",
                                alignItems: "center"
                            }}
                        >
                            <FTextField
                                sx={{ padding: 0, flex: 1 }}
                                id="standard-bare"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <SearchOutlined />
                                        </IconButton>
                                    ),
                                    style: { borderRadius: "5px" }
                                }}
                            />

                            <div style={{ display: "flex", gap: "8px" }}>
                                <FButton
                                    startIcon={<AddBoxIcon />}
                                    size="small"
                                    variant="contained"
                                    tooltip="New Empty Scene"

                                    onClick={handleAddSceneClick}
                                    sx={{
                                        marginRight: 1,
                                    }}
                                >
                                    New Scene
                                </FButton>

                                <ModelFileImporter ref={modelImporter} onFileRead={onFileRead} />
                                <FButton
                                    startIcon={<ArrowCircleUp />}
                                    tooltip="Import 3D/CAD files"
                                    sx={{
                                        marginRight: 1,
                                    }}
                                    onClick={onImportClick}
                                >
                                    Import File
                                </FButton>
                                <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                    <UserAvatar user={user} onThemeModeChange={onThemeModeChange} />
                                </div>
                            </div>
                        </div>
                        <Box
                            
                            ref={imageContainerRef}
                            sx={{
                                overflow: "auto",
                                padding: "8px 16px 16px 16px",
                                flex: 1,
                                backgroundColor: 'background.area'
                            }}
                        >
                            <FileGrid
                                folderList={folderList}
                                fileList={fileList}
                                onSelectFolder={clickOnFolderIcon}
                                onSelectFile={handleClickOnThumbnail}
                                onDeleteScene={confirmedDeleteScene}
                                onDeleteRender={confirmedDeleteRender}
                                onDeleteMaterial={confirmedDeleteMaterial}
                            />
                        </Box>
                    </Box>
                    
                </ResizableContainer>


            </main>
        </>
    );
}
