import React from 'react';
import { useSelectedNodes } from '../SelectedNodesContext';
import SceneTreeViewer from './SceneTreeViewer';
import { useEffect, useState } from 'react';

export type SceneTreeProps = {
  search: string; // Prop to filter nodes
};

export default function SceneTree({ search }: SceneTreeProps) {
  const {selectedNodeIds, setSelectedNodeIds } = useSelectedNodes();
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    globalThis.updateSceneTree = () => setForceUpdate((n) => n + 1);

    return () => {
      delete globalThis.updateSceneTree;
    };
  }, []);

  return (

      <SceneTreeViewer
        actionsList={['createGroup', 'clone', 'delete']}
        search={search}
        selectedNodeIds={selectedNodeIds}
        setSelectedNodeIds={setSelectedNodeIds}
        rootNodeId={-3}
        forceUpdate={forceUpdate}
      />

  );
};