import { Breadcrumbs, Link } from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view";
import { useEffect, useRef, useState } from "react";
import * as FileApi from "../../services/file-api";
import FolderIcon from '@mui/icons-material/FolderOutlined';

type FileTreeProps = {
  folderTree: FileApi.FSEntry[];
  selectedNodeId: string;
  renderRootId?: string; // Make it optional
  onSelectNode: (id: string) => Promise<void>;
};

export default function FolderTree({ folderTree, selectedNodeId, renderRootId, onSelectNode }: FileTreeProps) {
  const [currentFolderTree, setCurrentFolderTree] = useState<FileApi.FSEntry[]>([]);
  const [expandedTreeFolders, setExpandedTreeFolders] = useState<string[]>([]);
  const [breadcrumb, setBreadcrumb] = useState<FileApi.FSEntry[]>([]);
  const initialLoad = useRef(true); // Track if it's the initial load

  const findFolderById = (nodes: FileApi.FSEntry[], id: string): FileApi.FSEntry | null => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const result = findFolderById(node.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const buildBreadcrumb = (nodes: FileApi.FSEntry[], targetId: string): FileApi.FSEntry[] => {
    const path: FileApi.FSEntry[] = [];
    const findAndBuildPath = (nodes: FileApi.FSEntry[], targetId: string): boolean => {
      for (const node of nodes) {
        if (node.id === targetId) {
          path.push(node);
          return true;
        }
        if (node.children) {
          const foundInChildren = findAndBuildPath(node.children, targetId);
          if (foundInChildren) {
            path.unshift(node);
            return true;
          }
        }
      }
      return false;
    };
    findAndBuildPath(nodes, targetId);
    return path;
  };

  // Determine the actual render root ID
  const actualRenderRootId = renderRootId ?? (folderTree.length > 0 ? folderTree[0].id : '');

  useEffect(() => {
    const getAllFolderIds = (nodes: FileApi.FSEntry[]): string[] => {
      return nodes.flatMap((node) => [
        node.id,
        ...(node.children ? getAllFolderIds(node.children) : []),
      ]);
    };

    // Only expand all folders during the initial load
    if (folderTree && folderTree.length > 0 && initialLoad.current) {
      setExpandedTreeFolders(getAllFolderIds(folderTree));
      initialLoad.current = false;
    }

  }, [folderTree]);

  useEffect(() => {
    const renderRootFolder = findFolderById(folderTree, actualRenderRootId);

    if (renderRootFolder) {
      setCurrentFolderTree([renderRootFolder]);
    } else {
      setCurrentFolderTree(folderTree);
    }

    const newBreadcrumb = buildBreadcrumb(folderTree, actualRenderRootId);
    setBreadcrumb(newBreadcrumb);

  }, [folderTree, actualRenderRootId]);

  const handleBreadcrumbClick = (folderId: string) => {
    // Update the render root when a breadcrumb is clicked
    onSelectNode(folderId); // Ensure this updates renderRootId in the parent if needed
  };

  const handleClickOnTreeViewItem = (_event, id) => {
    onSelectNode(id);
  };

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedTreeFolders(itemIds);
    event.stopPropagation();
  };

  return (
    <div>
      {/* <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "1em" }}>
        {breadcrumb.map((folder) => (
          <Link
            key={folder.id}
            color="inherit"
            onClick={() => handleBreadcrumbClick(folder.id)}
            style={{ cursor: "pointer" }}
          >
            {folder.label}
          </Link>
        ))}
      </Breadcrumbs> */}

      <RichTreeView
        items={currentFolderTree}
        selectedItems={[selectedNodeId]} // Highlight the selected node
        onSelectedItemsChange={handleClickOnTreeViewItem}
        expansionTrigger="iconContainer"
        expandedItems={expandedTreeFolders}
        defaultExpandedItems={expandedTreeFolders}
        onExpandedItemsChange={handleExpandedItemsChange}
      />
    </div>
  );
}
