// Types
type FSEntry = {
  id: string;
  type: "folder" | "scene" | "render";
  label: string;
  thumbnail?: string;
  children: FSEntry[];
  modifiedAgo?: number;
  sceneId?: string;
};

// API constants
const fileApi = "/api/files"
const folderApi = "/api/folders"

/**
 * Load all files and folders
 **/ 
const loadFolders = async (): Promise<FSEntry[]> => {
  return await fetch(folderApi)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
}

/**
 * Load all files from a folder with the specified ID

 * @param relative path of the folder (relative from the user root)
 * @param includeFolders Whether to load files recursively
 * @param filetypes Filetypes to filter by
 * @param includeFolders Whether to include folders in the result
 * @returns All files from the folder filtered as requested
 */
const loadFilesForFolder = async (
  path: string, 
  recursive: boolean = true, 
  filetypes: string = "", 
  includeFolders: boolean = true
): Promise<FSEntry[]> => {
  const url = new URL(`${fileApi}/${path}`, window.location.href);
  url.searchParams.append("recursive", recursive.toString());
  url.searchParams.append("filetypes", filetypes);
  url.searchParams.append("includeFolders", includeFolders.toString());

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export {
  FSEntry,
  loadFolders,
  loadFilesForFolder
}