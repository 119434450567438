import { Folder } from "@mui/icons-material";
import { IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import * as FileApi from "../../services/file-api"
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { isMobile } from "react-device-detect";
import { useComponentSize } from "../../utils/use-component-size";
import { AskDeleteDialog } from "./AskDeleteDialog";
import { useDragItemContext } from '../DragItemContext';  // Import the context

type FileGalleryProps = {
  folderList: FileApi.FSEntry[],
  fileList: FileApi.FSEntry[],
  onSelectFolder: (id: string) => void,
  onSelectFile: (e: React.MouseEvent<HTMLImageElement>, file: FileApi.FSEntry) => void,
  onDeleteScene: (id: string) => void
  onDeleteRender: (id: string) => void
  onDeleteMaterial: (id: string) => void
}

export default function FileTree({ folderList, fileList, onSelectFolder, onSelectFile, onDeleteScene, onDeleteRender, onDeleteMaterial }: FileGalleryProps) {
  const imageContainerRef = useRef(null);
  const { width: imageContainerwidth } = useComponentSize(imageContainerRef);
  const [imageColumns, setImageColumns] = useState(1);
  const [askDeleteScene, setAskDeleteScene] = useState(null);
  const [askDeleteRender, setAskDeleteRender] = useState(null);
  const [askDeleteMaterial, setAskDeleteMaterial] = useState(null);
  const { setDraggedItem } = useDragItemContext();  // Access the dragged item from context

  useEffect(() => {
    setImageColumns(Math.floor(imageContainerwidth / 140));
  }, [imageContainerwidth]);

  function handleDelete({ type, id }): void {

 
    if (type === "scene") {
      setAskDeleteScene(id);
    } else if (type === "render") {
      setAskDeleteRender(id);
    } else if (type === "material") {
      setAskDeleteMaterial(id);
    }
  }

  function confirmedDeleteScene(): void {
    const id = askDeleteScene
    setAskDeleteScene(null);
    onDeleteScene(id)
  }

  function confirmedDeleteRender(): void {
    const id = askDeleteRender
    setAskDeleteRender(null);
    onDeleteRender(id)
  }

  function confiemdDeleteMaterial(): void {
    const id = askDeleteRender
    setAskDeleteMaterial(null);
    onDeleteMaterial(id)
  }


  const handleDragStart = (e, file: FileApi.FSEntry) => {
    setDraggedItem(file, "fileGrid");  // Pass the event to set custom data in dataTransfer

    const dragImage = e.target;

    const xOffset = 0; 
    const yOffset = 0; 

    e.dataTransfer.setDragImage(dragImage, xOffset, yOffset);


  };

  return (
    <div ref={imageContainerRef} >
      <AskDeleteDialog noun="Scene" open={!!askDeleteScene} onCancel={() => setAskDeleteScene(null)} onConfirm={confirmedDeleteScene} onClose={() => setAskDeleteScene(null)} />
      <AskDeleteDialog noun="Render" open={!!askDeleteRender} onCancel={() => setAskDeleteRender(null)} onConfirm={confirmedDeleteRender} onClose={() => setAskDeleteRender(null)} />
      <AskDeleteDialog noun="Material" open={!!askDeleteMaterial} onCancel={() => setAskDeleteMaterial(null)} onConfirm={confiemdDeleteMaterial} onClose={() => setAskDeleteMaterial(null)} />
      {folderList.length > 0 && (
        <ImageList cols={imageColumns} style={{ margin: 0 }} gap={8} >
          {folderList.map((folder, index) => (
            <ImageListItem
              key={index}
              onClick={_event => onSelectFolder(folder.id)}
            >
              <div style={{ display: "flex", flexDirection: "column", }}>
                <Folder
                  color="primary"
                  style={{
                    width: "100%",
                    flex: 1,
                    borderRadius: "5px"
                  }}
                />
                <ImageListItemBar
                  title={folder.label}
                  position="below"
                  sx={{
                    margin: "-10px 5px 10px 15px",                    
                    "& .MuiImageListItemBar-titleWrap": { padding: 0 }
                  }}
                />
              </div>

            </ImageListItem>
          ))}
        </ImageList>
      )}

      <ImageList cols={imageColumns} gap={12} sx={{ boxSizing: "border-box" }} style={{ gridTemplateColumns: `repeat(${imageColumns}, minmax(0, 1fr))` }}>
        {fileList.map((file, index) => (
          <ImageListItem
            key={index}
            sx={{ cursor: "pointer", }}
          >
            <div style={{ display: "flex", flexDirection: "column", }}>
              <img
                draggable={true}

                onDragStart={((e) => handleDragStart(e, file))}  // Set dragged file on drag start
              
                // onDragStart={(e) => dragStart(index)}
                // onDragEnd={(e) => onDraggingMaterial(undefined)}
                src={file.thumbnail}
                alt={file.label}
                loading="lazy"
                onClick={event => onSelectFile(event, file)}
                id={file.id}
                onError={(e: SyntheticEvent<HTMLImageElement>) => {
                  const me = e.target as HTMLImageElement;
                  me.onerror = null; // Prevents infinite loop if the fallback image also fails
                  me.src =
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjmDZt2n8ABkoCwgNiyRMAAAAASUVORK5CYII=";
                }}
                style={{
                  flex: 1,
                  borderRadius: "5px",
                  zIndex: 2,
                }}
              />
              {file.label && (
                <ImageListItemBar
                  title={file.label}
                  position="below"
                  sx={{
                    flex: 1,
                    backgroundColor: "background.label",
                    borderRadius: "0 0 5px 5px",
                    marginTop: "-5px",
                    padding: "10px 5px 0px 8px",
                    zIndex: 1,
                  }}
                />
              )}

            </div>
            {!isMobile &&
              <IconButton sx={{ position: "absolute", top: '0px', zIndex: 10 }} onClick={_event => handleDelete(file)} size="small">
                <GridDeleteIcon />
              </IconButton>
            }
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  )
}