import React from 'react';
import TextField from '@mui/material/TextField';

const FTextField = ({ sx = {}, ...props }) => {
    return (
        <TextField
            size="small"
            variant="outlined"
            
            hiddenLabel
            sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                ...sx, // Merge custom sx with provided sx prop
            }}
            {...props} // Spread the rest of the props to retain original functionality
        />
    );
};

export default FTextField;
