import { DragEvent, useContext, useEffect, useRef, useState } from "react";
import CanvasContextMenu from "./scene/CanvasContextMenu";
import type { MainModule } from "../typings/lys";
import { ImportDataContext } from "../utils/import-data-context";
import { AABB, createJpgBlob, cutoutThumbnail, lysImageBufferToRGBA } from "../utils/thumbnails";
import { useDragItemContext } from './DragItemContext';  // Import the context
import { useTheme } from "@mui/material";
import { User } from "../utils/user";
import { useSceneTabMode, SceneTabMode } from './SceneTabModeContext';
import StepFileDialog from "../pages/StepFileDialog";
import { importModelFromBufferInLys, isStepFile, readFileToImportData } from "./toolbar/ModelFileImporter";

interface BufferWithSize {
  ptr: number;
  size: number;
  width: number;
  height: number;
}

declare global {
  interface MainModuleWrapper {
    setFollowUserId: (userId: number) => void;
    importNamedModel: (modelName: string) => void;
    importFileModel: (modelName: string, x: number, y: number) => void;
    getMaterialNamesList: () => string;
    setUserName: (username: string) => void;
    getMaterialFromNodeId: (nodeId: number) => string;
    setMaterialName: (nodeId: number, name: string) => void;
    setPause: (pause: boolean) => void;
    updateMaterialThumbnails: () => void;
    changeScene: (sceneId: string) => void;
    setSceneName(name: string): void;
    getCurrentImage(): Promise<{ full: Blob; thumbnail: Blob } | null>;
    createTexture(filename: string, buffer: number, size: number): number;
    createEnvironment(filename: string, buffer: number, size: number): number;
    _quit: () => void;
  }

  interface Window {
    lys: MainModule & MainModuleWrapper;
    onSceneNameUpdate: (name: string) => void;
    onMaterialListUpdate: (materialList: string) => void;
    onUsersListUpdate: (activeUsersList: string) => void;
    onCameraListUpdate: (cameraList: string) => void;
    onMaterialThumbnailUpdate: (id: number, dataurl: string) => void;
    onUpdateSceneThumbnail: () => void;
    onMouseModeChange: (mode: string) => void;
    onReplayBegin: () => void;
    onReplayEnd: () => void;
    onWebsocketConnectionStatusChanged: (connected: boolean) => void;
  }
}

export type WebGLCanvasProps = {
  user: User;
  sceneId: string;
  mouseMode: string;
  renderMode: string;

  centerAndFit: boolean;
  onCenterAndFitDone: (done: boolean) => void;
 
  onSceneNameUpdate: (name: string) => void;
  onActiveUsersListChange: (users: any) => void;
  onLoadMessage: (message: string) => void;
  onUpdateSceneThumbnail: () => void;
  onConnectionChanged: (connected: boolean) => void;

  setMaterialTypes: (materialTypes: any) => void;
  currentlyDraggedMaterial: any;
};

function WebGLCanvas({
  mouseMode,
  centerAndFit,
  onCenterAndFitDone,
  renderMode,
  onSceneNameUpdate,
  onActiveUsersListChange,
  user,
  setMaterialTypes,
  onLoadMessage,
  onConnectionChanged,
  sceneId,
  onUpdateSceneThumbnail,
}: WebGLCanvasProps) {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();


  const [showMessage, setShowMessage] = useState(false);
  const lysStartedInitializing = useRef(false); // strict mode mounts components twice, wasm lys can't survice that
  const [lysIsReady, setLysIsReady] = useState(false);

  const ref = useRef(null);
  const { importData, setImportData } = useContext(ImportDataContext);

  const { draggedItem, clearDraggedItem, dragType, setDraggedItem } =
    useDragItemContext();
  const previousNodeIdRef = useRef<number | null>(-1); // No re-renders
  const previousMaterialIdRef = useRef<number | null>(-1); // No re-renders
  const { sceneTabMode, setSceneTabMode } = useSceneTabMode();

  const theme = useTheme(); // Get theme for accessing primary color

  const lysFunctionCall = (mode: string) => {
    if (globalThis.lys == undefined) return;

    switch (mode) {
      case "Center":
        globalThis.lys._setCenterAndFit();
        "tumble";
        break;
      case "Select":
        globalThis.lys._setControlModeSelect();
        break;
      case "Tumble":
        globalThis.lys._setControlModeTumble();
        break;
      case "Pan":
        globalThis.lys._setControlModePan();
        break;
      case "Dolly":
        globalThis.lys._setControlModeDolly();
        break;
      case "Zoom":
        globalThis.lys._setControlModeZoom();
        break;
      default:
        console.warn("unknown lys control mode", mode);
    }
  };

  useEffect(() => {
    if (globalThis.lys == undefined || renderMode === undefined) return;

    switch (renderMode) {
      case "Wire":
        globalThis.lys._setWireFrameRenderMode();
        break;
      case "Fast":
        globalThis.lys._setRealTimeRenderMode();
        break;
      case "Photo":
        globalThis.lys._setRaytraceRenderMode();
        break;
      default:
        console.warn("unknown render mode ", renderMode);
    }
  }, [renderMode]);

  useEffect(() => {
    lysFunctionCall(mouseMode);
  }, [mouseMode]);

  useEffect(() => {
    if (globalThis.lys == undefined) return;
    globalThis.lys._setCenterAndFit();
    onCenterAndFitDone(false);
  }, [centerAndFit]);

  useEffect(() => {
    if (!ref.current) return;

    const canvas = ref.current;

    if (dragType === "fileGrid" || dragType === "materialList") {
      canvas.style.outlineColor = theme.palette.primary.main;
      canvas.style.outlineWidth = "2px";
      canvas.style.outlineStyle = "solid";
      canvas.style.outlineOffset = "-2px";
    } else {
      // Reset the outline if no relevant dragType is active
      canvas.style.outline = "none";
    }
  }, [dragType]);

  useEffect(() => {
    if (!!importData && lysIsReady) {
      if (!isStepFile(importData.filename)) {
        console.log(`Importing file: ${importData?.filename} size=${importData?.buffer.byteLength}`);
        importModelFromBufferInLys(importData.buffer, importData.filename);
        setImportData(null);
      }
      // If it is step file the step file dialog will be shown - see StepFileDialog in this file
    }
  }, [importData, lysIsReady]);
 
  const handleDragEnter = (e: DragEvent<HTMLCanvasElement>) => {
    e.preventDefault();

    if (draggedItem) {
      console.log("Dragged item: ", draggedItem);
      console.log("Drag type: ", dragType);
      if (dragType == "fileGrid") {
        console.log("FileGrid drag enter");
        fetchMaterialById(draggedItem.id).then((material) => {
          let materialId = globalThis.lys.createMaterialFromJson(material);
          draggedItem.materialId = materialId;
        });
      }
    }
  };

  const handleDragLeave = (e: DragEvent<HTMLCanvasElement>) => {
    e.preventDefault();

    if (draggedItem) {
      if (dragType === "materialList" || dragType === "fileGrid") {
        if (
          previousNodeIdRef.current >= 0 &&
          previousMaterialIdRef.current >= 0
        ) {
          globalThis.lys.setMaterial(
            previousNodeIdRef.current,
            previousMaterialIdRef.current,
            true,
          ); // re-establish old node
        }

        if (
          dragType === "fileGrid" &&
          draggedItem.materialId &&
          draggedItem.materialId >= 0
        ) {
          globalThis.lys.deleteMaterialById(draggedItem.materialId, true);
          draggedItem.materialId = undefined;
          previousNodeIdRef.current = -1;
          previousMaterialIdRef.current = -1;
        }
      }
    }
  };

  const handleDragOver = (e: DragEvent<HTMLCanvasElement>) => {
    e.preventDefault();

    // Check if this is an internal drag event
    if (draggedItem) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (dragType === "materialList" || dragType === "fileGrid") {
        const nodeId = globalThis.lys.getNodeAt(x, y);

        if (
          draggedItem.materialId != undefined &&
          previousNodeIdRef.current !== nodeId
        ) {
          // hovering new node

          if (
            previousNodeIdRef.current >= 0 &&
            previousMaterialIdRef.current >= 0
          ) {
            globalThis.lys.setMaterial(
              previousNodeIdRef.current,
              previousMaterialIdRef.current,
              true,
            ); // re-establish old node
          }
          // save currently hovering nodes state
          if (
            nodeId >= 0 &&
            draggedItem.materialId &&
            draggedItem.materialId >= 0
          ) {
            const currentMaterialId = globalThis.lys
              .getNodeById(nodeId)
              ?.getMaterial()
              ?.getId();
            previousMaterialIdRef.current = currentMaterialId;
          }

          if (nodeId >= 0)
            globalThis.lys.setMaterial(nodeId, draggedItem.materialId, true);

          previousNodeIdRef.current = nodeId;
        }
      }
    }
  };

  async function fetchMaterialById(id: string): Promise<string> {
    const response = await fetch(`/api/materials/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch material with id ${id}`);
    }
    const material = await response.text();
    return material;
  }

  const handleDrop = (e: DragEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // Check if this is an internal drag event
    if (draggedItem) {
      // Handle the internal drag
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (dragType === "materialList" || dragType === "fileGrid") {
        previousNodeIdRef.current = -1;
        previousMaterialIdRef.current = -1;
      }
    } else if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // Handle OS filesystem drag
      const file = e.dataTransfer.files[0];
  
      console.debug("Dropped file from OS: ", file.name);
  
      // Handle .step or .stp file import
      if (file.name.endsWith(".step") || file.name.endsWith(".stp")) {
        readFileToImportData(file, setImportData);
      }
    }

    clearDraggedItem();
    e.dataTransfer.clearData();
  };

  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxWidth: 800,
    width: "40%",
    zIndex: 100,
    p: 4,
  };

  // Monitor parent size using ResizeObserver
  useEffect(() => {
    if (!ref.current || !ref.current.parentElement) return;

    const parent = ref.current.parentElement;

    const updateSize = (entries) => {
      const entry = entries[0];
      const width = entry.contentRect.width;
      const height = entry.contentRect.height;

      if (width == 0 && height == 0) return;

      ref.current.style.setProperty("height", height);
      ref.current.style.setProperty("width", width);

      setWidth(width);
      setHeight(height);

      if (globalThis.lys) {
        globalThis.lys._setSize(width, height);
        globalThis.lys.setPause(false);
      }
    };

    const observer = new ResizeObserver((entries) => {
      updateSize(entries);
    });

    observer.observe(parent);

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  const handleBlur = () => {
    if (!window.lys) return;
    console.log("Focus lost, pausing");
    window.lys.setPause(true); //Pause on loosing focus
  };

  const handleFocus = () => {
    if (!window.lys) return;

    console.log("Focus gained, unpausing");
    window.lys.setPause(false);
  };

  const initializeLys = async () => {
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

    globalThis.lysIsReady = () => { //lys will call back on this function when ready
      console.log("lys is ready");
      setLysIsReady(true);
    };

    try {
      const mod = isIos
        ? await import("../../.build/lys/wasm/bin/lys_no_growth.mjs")
        : await import("../../.build/lys/wasm/bin/lys_growth.mjs");

      const instance: MainModule & MainModuleWrapper = await mod.default({
        arguments: ["--open", sceneId, "--username", user.name],
        canvas: (() => document.getElementById("canvas"))(),
      });

      console.debug("WebGLCanvas component mounted");
      window["lys"] = instance;
      instance["setSceneName"] = instance.cwrap("setSceneName", null, [
        "string",
      ]);
      instance["importNamedModel"] = instance.cwrap("importNamedModel", null, [
        "string",
      ]);
      instance["changeScene"] = instance.cwrap("changeScene", null, ["string"]);
      instance["importFileModel"] = instance.cwrap("importFileModel", null, [
        "string",
        "number",
        "number",
      ]);
      instance["getMaterialNamesList"] = instance.cwrap(
        "getMaterialNamesList",
        "string",
        null,
      );
      instance["setUserName"] = instance.cwrap("setUserName", null, ["string"]);
      instance["setPause"] = instance.cwrap("setPause", null, ["number"]);
      instance["updateMaterialThumbnails"] = instance.cwrap(
        "updateMaterialThumbnails",
        null,
        null,
      );
      instance["createTexture"] = instance.cwrap("createTexture", "number", [
        "string",
        "number",
        "number",
        "boolean",
      ]);
      instance["createEnvironment"] = instance.cwrap(
        "createEnvironment",
        "number",
        ["string", "number", "number", "boolean"],
      );
      instance["getCurrentImage"] = async () => {
        const bufferAndSize = (
          instance as any
        ).captureFrame() as BufferWithSize;
        const array = new Uint8Array(
          instance.HEAP8.buffer,
          bufferAndSize.ptr,
          bufferAndSize.size,
        );
        const imageData = lysImageBufferToRGBA(
          array,
          bufferAndSize.width,
          bufferAndSize.height,
        );
        (instance as any).freeFrame(bufferAndSize.ptr);
        const aabb: AABB = cutoutThumbnail(imageData);

        if (!aabb) return null;

        return {
          full: await createJpgBlob(imageData),
          thumbnail: await createJpgBlob(imageData, aabb, {
            width: 128,
            height: 128,
          }),
        };
      };

      if (ref.current) {
        const h: number = ref.current.parentElement.offsetHeight + 1;
        const w: number = ref.current.parentElement.offsetWidth + 1;

        ref.current.style.setProperty("height", h);
        ref.current.style.setProperty("width", w);

        setWidth(w);
        setHeight(h);
        console.log("Setting size to: ", w, h);
        instance._setSize(w, h);
      }

      setMaterialTypes(JSON.parse(window.lys.getMaterialNamesList()));

      window.addEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);

      const focus: boolean = document.hasFocus();
      console.log("Focus: ", focus);

      window.lys.setPause(!focus);

      //wasm loaded - erase default "loading wasm"
      onLoadMessage("");

    } catch (error) {
      if (error.name == "ExitStatus") console.debug("WASM exit gracefully");
      else if (error.name == "RuntimeError") {
        console.error("RuntimeError from Wasm: ", error);
      } else {
        console.error("Unexpected error during Wasm quit", error);
      }
    };
  }

  useEffect(() => {

    if (!lysStartedInitializing.current) {
      lysStartedInitializing.current = true;
      initializeLys();
  
      window.onSceneNameUpdate = (name: string) => {
        onSceneNameUpdate(name);
      };
      window.onUsersListUpdate = (activeUsersList) => {
        onActiveUsersListChange(JSON.parse(activeUsersList));
      };
      window.onUpdateSceneThumbnail = () => {
        onUpdateSceneThumbnail();
      };
      window.onWebsocketConnectionStatusChanged = (connected: boolean) => {
        onConnectionChanged(connected);
      };
    }
    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);

      if (window.lys) {
        console.log("WebGLCanvas component unmounted");

        console.log(
          "WebAssembly memory before quit:",
          window.lys.HEAP8.length / 1024 / 1024,
          "MB",
        );
        window.lys?._quit();
        window.lys = null;
      }
    };
  }, []);

  useEffect(() => {
    if (globalThis.lys) {
      console.debug("Scene change to: ", sceneId, "but reusing lys.wasm");
      globalThis.lys.changeScene(sceneId);
    }
  }, [sceneId]);

  const [rightClickMenuLocation, setRightClickMenuLocation] = useState(null);
  const [eventCoordinates, setEventCoordinates] = useState<[number, number] | null>(null);

  // Create a new left-click event
  const leftClickEvent = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window,
  });

  const handleRightClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    console.log("Right click event");
    // Find the target element or the element you want to dispatch the event to
    const targetElement = event.currentTarget; // or any specific element

    // Dispatch the left-click event
    targetElement.dispatchEvent(leftClickEvent);

    const x = event.clientX;
    const y = event.clientY;
    setRightClickMenuLocation([x, y]);

    const rect = event.currentTarget.getBoundingClientRect();
    setEventCoordinates([x - rect.left, y - rect.top]);

    event.preventDefault();
    event.stopPropagation();
  };

  const handleDoubleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const nodeId: number = globalThis.lys.getNodeAt(x, y);

    if (nodeId >= 0) setSceneTabMode(SceneTabMode.materials);
    else setSceneTabMode(SceneTabMode.envs);

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <canvas
        ref={ref}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        style={{
          boxSizing: "border-box",
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#ff0000",
          margin: 0,
          display: "block",
          zIndex: 0,
          // width:"100%",
          // height:"100%",
          overflow: "hidden",
        }}
        width={width}
        height={height}
        onContextMenu={handleRightClick}
        onDoubleClick={handleDoubleClick}
        className="App-renderwindow"
        id="canvas"
      />

      <CanvasContextMenu eventCoordinates={eventCoordinates} location={rightClickMenuLocation} handleClose={() => setRightClickMenuLocation(null)} />
      {lysIsReady && <StepFileDialog importData={importData} onLoadMessage={onLoadMessage} onClose={() => setImportData(null)}/>}

    </>
  );
}
export default WebGLCanvas;
