import React, { useRef, ChangeEvent, useCallback } from 'react';
import { TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface SearchBarProps {
    onSearch: (term: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Clear previous debounce timer
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set new debounce timer
        debounceTimeout.current = setTimeout(() => {
            onSearch(value);
        }, 300); // Adjust the delay as needed (300ms here)
    }, [onSearch]);

    const handleClear = (): void => {
        if (inputRef.current) {
            inputRef.current.value = '';
            onSearch('');
        }
    };

    return (
        <div>
            <TextField
                inputRef={inputRef}
                sx={{
                    padding: 1,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                hiddenLabel
                size="small"
                fullWidth
                variant="outlined"
                onChange={handleSearchChange}
                placeholder="Search..."
                aria-label="Search"
                role="searchbox"
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleClear} aria-label="clear search">
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
};

export default SearchBar;
