import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { isValidEmail, AuthenticatedUser } from "../utils/user";

async function loginUser(credentials: {
    email: string;
    password: string;
}): Promise<Response> {
    return fetch("/api/auth", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
    });
}

export default function Login({ onUser }: { onUser: (user: AuthenticatedUser) => void }) {
    const [email, setEmail] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const email = searchParams.get("email");
        const password = searchParams.get("token");

        if (email && password) {
            submitForm(email, password);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
        submitForm(email, password);
    };

    const submitForm = async (email, password) => {
        const response = await loginUser({
            email,
            password,
        });

        if (response.status == 200) {
            const { user }: { user: AuthenticatedUser } = await response.json();
            window.localStorage.setItem("user", JSON.stringify(user));
            onUser(user);
            return;
        }

        if (response.status == 202) {
            setMessage("Check your email for a one time sign in link.");
            setError(null)
        }

        const { message } = await response.json();
        setError(message ?? "You can signup for early access.");
    };

    const validate = () => {
        return isValidEmail(email);
    };

    return (
        <div>
            <Box
                display={isMobileOnly ? undefined : "flex"}
                justifyContent={isMobileOnly ? undefined : "center"}
                alignItems={isMobileOnly ? undefined : "center"}
                textAlign="center"
                minHeight="100vh"
            >
                <Paper
                    sx={isMobileOnly ? { height: "100vh" } : undefined}
                    elevation={10}
                    style={{ padding: 30 }}
                >
                    <Typography variant="h2">Figurement</Typography>
                    <Typography variant="subtitle1">
                        3D Product Rendering and Visualization
                    </Typography>
                    <Typography variant="subtitle1">
                        Easy | Collaborative | Cloud Powered
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <div style={{ paddingTop: 30 }}>
                            <TextField
                                fullWidth
                                label="Email"
                                required
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            ></TextField>
                        </div>
                        <div style={{ paddingTop: 15 }}>
                            <TextField
                                fullWidth
                                label="Password (optional)"
                                onChange={(e) => setPassword(e.target.value)}
                                type={"password"}
                            ></TextField>
                        </div>
                        <div style={{ paddingTop: 15 }}>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={!validate()}
                            >
                                {" "}
                                {!password && email ? "Send me a login code" : "Log in"}{" "}
                            </Button>
                        </div>
                        {error && (
                            <Typography
                                variant="subtitle1"
                                style={{ paddingTop: 30 }}
                            >
                                ERROR: {error}
                            </Typography>
                        )}
                        {message && (
                            <Typography
                                variant="body1"
                                style={{ paddingTop: 30 }}
                            >
                                {message}
                            </Typography>)}

                        <div style={{ paddingTop: 15 }}>
                            <Button variant="text" href={`/reset-password?email=${!!email ? email : ""}`}>
                                Forgot my password{" "}
                            </Button>
                        </div>
                    </form>
                </Paper>
            </Box>
        </div>
    );
}
