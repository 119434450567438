// KeyboardShortcutsModal.tsx
import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { actions, ActionKey, ActionContext } from './actions';
import { useKeyboardShortcuts } from './KeyboardShortcutsContext';

const KeyboardShortcutsModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { shortcuts, setShortcut } = useKeyboardShortcuts();

  const handleShortcutChange = (action: ActionKey, value: string) => {
    setShortcut(action, value); // Directly save the shortcut as it's modified
  };

  const context: ActionContext = {
    selectedNodeIds: [],
    setSelectedNodeIds: () => { },
    // Other context properties can be defined here or be fetched as needed
  };

  const shortcutCategories = [
    { title: 'Camera', actions: ['addCamera', 'centerAndFit'] },
    { title: 'Environment', actions: ['showAllParts'] },
    { title: 'File', actions: ['saveMaterialToLibrary', 'delete', 'clone'] },
    { title: 'Materials', actions: ['linkMaterial', 'unlinkMaterial'] },
    { title: 'General', actions: ['lockNode', 'unlockNode'] },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Keyboard Shortcuts
        <IconButton aria-label="close" onClick={onClose} size="small">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {shortcutCategories.map((category) => (
          <Box key={category.title} sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              {category.title}
            </Typography>
            {category.actions.map((actionKey) => {
              const actionLabel = actions[actionKey as ActionKey].label;

              // Determine if label is a string or a function and get the final label text
              const label =
                typeof actionLabel === 'function'
                  ? actionLabel(context) // Call with context if function
                  : actionLabel;

              return (
                <Box
                  key={actionKey}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 1,
                  }}
                >
                  <Typography>{label}</Typography>
                  <Box
                    component="input"
                    type="text"
                    value={shortcuts[actionKey as ActionKey]}
                    onChange={(e) => handleShortcutChange(actionKey as ActionKey, e.target.value)}
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '4px 8px',
                      width: '50%',
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default KeyboardShortcutsModal;
