import React, { useEffect, useState } from 'react';
import { actions, ActionKey, ActionContext } from '../actions';
import { useSelectedNodes } from '../SelectedNodesContext';
import { useLibraryUpdateContext } from '../LibraryReloadContext';
import ActionMenu from '../ActionMenu'; // Import the common component

interface CanvasContextMenuProps {
  location: [number, number] | null;
  eventCoordinates: [number, number] | null;
  handleClose: () => void;
}

const CanvasContextMenu: React.FC<CanvasContextMenuProps> = ({
  location,
  eventCoordinates,
  handleClose,
}) => {
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodes();
  const { reloadLibrary } = useLibraryUpdateContext();

  const actionContext: ActionContext = {
    selectedNodeIds,
    setSelectedNodeIds,
    reloadLibrary,
  };

  type MenuAction = ActionKey | 'divider';

  const [menuActions, setMenuActions] = useState<MenuAction[]>([]);

  useEffect(() => {
    if (eventCoordinates) {
      const [x, y] = eventCoordinates;
      const nodeId = globalThis.lys.getNodeAt(x, y);
      if (nodeId < 0) {
        setMenuActions(['centerAndFit', 'showAllParts']);
      } else {
        setMenuActions([
          'centerAndFit',
          'showAllParts',
          'divider',
          'clone',
          'delete',
          'groupParts',
          'divider',
          'lockNode',
          'unlockNode',
          'divider',
          'hideNode',
          'showHiddenNode',
          'divider',
          'unlinkMaterial',
          'linkMaterial',
          'saveMaterialToLibrary',
        ]);
      }
    }
  }, [eventCoordinates]);

  return (
    <ActionMenu
      open={location != null}
      handleClose={handleClose}
      location={location}
      menuActions={menuActions}
      actionContext={actionContext}
    />
  );
};

export default CanvasContextMenu;
