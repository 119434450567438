import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define types for parameters and materials
export interface Parameter {
  name: string;
}

export interface ColorParameter extends Parameter {
  type: 'color';
  value: string;
}

export interface TextureParameter extends Parameter {
  type: 'texture';
  value: number;
}

export interface NumericParameter extends Parameter {
  type: 'double';
  value: number;
}

export type AnyParameter = ColorParameter | TextureParameter | NumericParameter;

export interface MaterialWithProperties {
  id: number;
  type: string;
  name: string;
  thumbnail: string;
  parameters: Parameter[];
}

interface SimpleMaterial {
  id: number;
  type: string;
  name: string;
  baseColor: { x: number; y: number; z: number };
}

interface MaterialsContextType {
  materials: SimpleMaterial[];
  selectedMaterialIds: number[];
  selectedMaterialProperties: MaterialWithProperties[];
  setSelectedMaterialIds: React.Dispatch<React.SetStateAction<number[]>>; 
  setMaterialType: (newType: string) => void;
  setMaterialParameter: (parameterName: string, newValue: any) => void;
}

// Create context with undefined default value
const MaterialsContext = createContext<MaterialsContextType>(undefined);

interface MaterialProviderProps {
  children: ReactNode;
}

export const MaterialProvider = ({ children }: MaterialProviderProps) => {
  const [materials, setMaterials] = useState<SimpleMaterial[]>([]);
  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
  const [selectedMaterialProperties, setSelectedMaterialProperties] = useState<MaterialWithProperties[]>([]);

  const [forceUpdate, setForceUpdate] = useState(0);

  // useEffect that runs whenever forceUpdate changes
  useEffect(() => {
    if (!globalThis.lys) return;

    const materialIds = globalThis.lys.getAllMaterialIds() as number[];

    const fetchedMaterials = materialIds.flatMap((id: number) => {
      const material = globalThis.lys.getMaterialById(id);
      if (material)
        return [{
          id: id,
          type: material.getType(),
          name: material.getName(),
          baseColor: material.baseColor,
        }];
      return [];
    });

    setMaterials(fetchedMaterials);

    if (selectedMaterialIds.length === 0) return;

    const selectedProperties = selectedMaterialIds.map((materialId) => {
      const material = globalThis.lys.getMaterialById(materialId);
      return material ? JSON.parse(material.toJSONString()) : null;
    }).filter(Boolean) as MaterialWithProperties[];

    setSelectedMaterialProperties(selectedProperties);
  }, [forceUpdate, selectedMaterialIds]);

  // Update material type for the first selected material
  const setMaterialType = (newType: string) => {
    setSelectedMaterialProperties((prevProps) => {
      if (prevProps.length === 0) return prevProps;

      const updatedMaterial = { ...prevProps[0], type: newType };
      globalThis.lys.changeMaterialType(updatedMaterial.id, newType, true);

      return [updatedMaterial, ...prevProps.slice(1)];
    });
  };

  // Update a specific material parameter for the first selected material
  const setMaterialParameter = (parameterName: string, newValue: any) => {
    setSelectedMaterialProperties((prevProps) => {
      if (prevProps.length === 0) return prevProps;

      const updatedParameters = prevProps[0].parameters.map((param) =>
        param.name === parameterName ? { ...param, value: newValue } : param
      );
      const updatedMaterial = { ...prevProps[0], parameters: updatedParameters };

      globalThis.lys.setMaterialParameters(updatedMaterial.id, JSON.stringify(updatedMaterial), true);

      return [updatedMaterial, ...prevProps.slice(1)];
    });
  };

  useEffect(() => {
    globalThis.updateMaterialList = () => setForceUpdate((n) => n + 1);

    return () => {
      delete globalThis.updateMaterialList;
    };
  }, []);

  return (
    <MaterialsContext.Provider
      value={{
        materials,
        selectedMaterialIds,
        selectedMaterialProperties,
        setSelectedMaterialIds,
        setMaterialType,
        setMaterialParameter,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};

// Custom hook to use the MaterialsContext
export const useMaterials = (): MaterialsContextType => {
  const context = useContext(MaterialsContext);
  if (!context) {
    throw new Error('useMaterials must be used within a MaterialProvider');
  }
  return context;
};
