import { createContext, useState } from 'react';

export type ImportData = {
  filename: string, 
  buffer: Uint8Array,
}

export type ImportDataContextType = {
  importData: ImportData | null,
  setImportData: React.Dispatch<React.SetStateAction<ImportData | null>>,
}

export const ImportDataContext = createContext<ImportDataContextType | null>(null);

export const ImportDataProvider = ({ children }) => {
  const [importData, setImportData] = useState<ImportData | null>(null);

  return (
    <ImportDataContext.Provider value={{ importData, setImportData }}>
      {children}
    </ImportDataContext.Provider>
  );
};
